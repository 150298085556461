export default [
  // {
  //   title: 'Pano',
  //   route: 'statistics',
  //   icon: 'GridIcon',
  // },
  // {
  //   title: 'CRM',
  //   route: 'crm',
  //   icon: 'TrelloIcon',
  // },
  // {
  //   title: 'Yazılı Kanal',
  //   route: 'apps-chat',
  //   icon: 'MessageCircleIcon',
  // },
  // {
  //   title: 'Ajanda',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Rehber',
  //   route: 'phone-book',
  //   icon: 'BookIcon',
  // },
  {
    header: globalThis._lang("t_liveDashboards")
  },
  {
    title: globalThis._lang("t_liveDashboard"),
    route: 'spv_dashboard',
    icon: 'MonitorIcon',
  },
  {
    title: 'Wallboard',
    icon: 'PieChartIcon',
    route: 'wallboard'
  },
  {
    title: 'Dashboard',
    icon: 'BarChartIcon',
    route: 'dashboard'
  },
  {
    title: globalThis._lang("t_callAnalyze"),
    icon: 'ActivityIcon',
    route: 'call_analize',
    img: 'callexper_slogo.png'
  },
  {
    header: globalThis._lang("t_tools")
  },
  {
    title: globalThis._lang("t_dialer"),
    icon: 'SlidersIcon',
    route: 'dialer'
  },
  {
    title: globalThis._lang("t_goalManagement"),
    route: 'goal_management',
    icon: 'TargetIcon',
  },
  {
    title: globalThis._lang("t_reports"),
    icon: 'SearchIcon',
    route: 'reports',
  },
  {
    title: globalThis._lang("t_scheduler"),
    icon: 'TrelloIcon',
    route: 'scheduler',
  },
  {
    title: globalThis._lang("t_mAlerts"),
    icon: 'BellIcon',
    route: 'alerts',
  },
  {
    title: globalThis._lang("t_quality"),
    icon: 'CheckSquareIcon',
    route: 'quality',
  },
  {
    title: globalThis._lang("t_sms"),
    icon: 'MessageSquareIcon',
    route: 'sms'
  },
  {
    title: globalThis._lang("t_eMail"),
    icon: 'MailIcon',
    route: 'email'
  },


  // {
  //   title: 'IVN',
  //   route: null,
  //   icon: 'VoicemailIcon',
  //   disabled: true
  // },
  {
    header: globalThis._lang("t_admin")
  },
  {
    title: globalThis._lang("t_systemManagement"),
    icon: 'ToolIcon',
    route: 'system_management'
  },
  {
    title: globalThis._lang("t_operationManagement"),
    icon: 'UserIcon',
    route: 'operation_management',

  },
  {
    title: globalThis._lang("t_integrations"),
    icon: 'LayersIcon',
    route: 'integrations',

  },
  {
    title: globalThis._lang("t_settings"),
    icon: 'SettingsIcon',
    route: 'settings'

  },













]
