<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <b-img
        :style="{
          filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
        }"
        class="mr-2 ml-50"
        width="78px"
        height="29px"
        src="v_main_logo.png"
        alt="logo"
      />
      <b-button
        v-if="menu_list.includes('agent_dashboard')"
        @click="selected_button = 'statistics'"
        :to="'/agent_dashboard'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_dashboard')"
        :style="{ opacity: selected_button == 'statistics' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'statistics' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-2"
      >
        <feather-icon size="20" icon="GridIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('crm')"
        @click="selected_button = 'crm'"
        :to="'/crm'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="'CRM'"
        :style="{ opacity: selected_button == 'crm' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="selected_button == 'crm' ? 'secondary' : 'flat-secondary'"
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="UsersIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('calendar')"
        @click="selected_button = 'apps-calenda'"
        :to="'/calendar'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_calendar')"
        :style="{ opacity: selected_button == 'apps-calenda' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'apps-calenda' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="CalendarIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('phone_book')"
        @click="selected_button = 'phone-book'"
        :to="'/phone_book'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_phoneBook')"
        :style="{ opacity: selected_button == 'phone-book' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'phone-book' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="BookIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('evaluated_records')"
        @click="selected_button = 'evaluated-records'"
        :to="'/evaluated_records'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_evaluatedRecords')"
        :style="{ opacity: selected_button == 'evaluated-records' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'evaluated-records'
            ? 'secondary'
            : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="CheckSquareIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('external_chat')"
        @click="selected_button = 'external-chat'"
        :to="'/external_chat'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_externalChat')"
        :style="{ opacity: selected_button == 'external-chat' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'external-chat' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="MessageCircleIcon" />
      </b-button>
      <!-- <b-form-checkbox
        v-model="menu.hidden"
        class="custom-control-primary"
        name="dont_connect_machines"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-form-checkbox
        @change="changeMenuType"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox> -->

      <div class="d-sm-flex d-none user-nav">
        <!-- <p class="user-name font-weight-bolder mb-0 mr-2">
          {{ username.split("@")[0] }}
        </p> -->

        <!-- <b-row class="mr-1">
          <span class="user-status">{{ productivity }}</span>
          <feather-icon
            size="16"
            :icon="60 > 50 ? 'TrendingUpIcon' : 'TrendingDownIcon'"
            class="mr-50 ml-50 text-success"
          />
          <span class="user-status">9999</span>
        </b-row> -->
      </div>

      <b-button-group v-if="status.length > 0" class="mr-1" size="sm">
        <b-button :variant="status_color.split('-')[1]">
          {{ statu_timer_text }}
        </b-button>
        <b-dropdown
          :disabled="!is_registered"
          v-if="selected_status.changeable == 1"
          v-model="selected_status"
          :text="selected_status.display_name"
          :variant="status_color"
        >
          <b-dropdown-item
            v-for="(item, i) in status.filter((e) => e.changeable == 1)"
            :key="i"
            @click="on_status_change(item)"
          >
            {{ item.display_name }}
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-else
          v-model="selected_status"
          :text="selected_status.display_name"
          :variant="status_color"
          disabled
        >
          <b-dropdown-item
            v-for="(item, i) in status.filter((e) => e.changeable == 0)"
            :key="i"
            @click="on_status_change(item)"
          >
            {{ item.display_name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
      <!-- <span class="mt-1 mb-1">{{ username.split("@")[0].toUpperCase() }}</span> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="42"
            :variant="is_registered ? 'light-success' : 'light-secondary'"
            badge
            class="badge-minimal"
            badge-offset="-0.5em"
            :badge-variant="is_registered ? 'success' : 'secondary'"
          >
            <template class="mt-1" #badge>
              <feather-icon :icon="is_registered ? 'WifiIcon' : 'WifiOffIcon'"
            /></template>
            {{ username.substr(0, 1).toUpperCase() }}
          </b-avatar>
        </template>
        <div class="d-flex align-items-center py-25">
          <b-form-checkbox
            v-if="user_agent"
            class="custom-control-primary ml-1"
            name="check-button"
            switch
            :checked="user_agent.isRegistered()"
          >
            <span class="switch-icon-left">
              <feather-icon class="mt-25" icon="WifiIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon class="mt-25" icon="WifiOffIcon" />
            </span>
          </b-form-checkbox>
          <span>{{
            is_registered ? lang("t_connected") : lang("t_disconnected")
          }}</span>
        </div>
        <!-- <b-dropdown-item @click="login" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span class="ml-50">Register</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="unregister"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span class="ml-50">UnRegister</span>
        </b-dropdown-item> -->
        <div class="d-flex align-items-center py-25">
          <b-form-checkbox
            @change="
              () => {
                skin = isDark ? 'semi-dark' : 'dark';
              }
            "
            class="custom-control-primary ml-1"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon
                class="mt-25"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                class="mt-25"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
              />
            </span>
          </b-form-checkbox>
          <span>{{
            skin != "dark" ? lang("t_dayMode") : lang("t_nightMode")
          }}</span>
        </div>
        <!-- <b-dropdown-divider />

        <b-dropdown-item
          @click="skin = isDark ? 'semi-dark' : 'dark'"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />

          <span class="ml-50">{{
            skin == "dark" ? "Aydınlık Mod" : "Karanlık Mod"
          }}</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          v-if="other_permission.includes('agent_list')"
          @click="
            () => {
              get_live_agent();
              modal_agent_list = true;
            }
          "
          link-class="d-flex align-items-center py-25"
        >
          <feather-icon size="16" icon="UsersIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_agentList") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.modal-settings
          link-class="d-flex align-items-center py-25"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_settings") }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          @click="logout"
          link-class="d-flex align-items-center py-0"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_logOut") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      :title="lang('t_agentList')"
      hide-footer
      centered
      v-model="modal_agent_list"
      dialog-class="dClass"
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        style="max-height: 100vh; overflow-y: auto"
        class="mt-1"
        :items="agent_list"
        :fields="agent_list_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'Username'"
      >
        <template #cell(Time)="data">
          {{ timer(data.value) }}
        </template>
        <template #cell(Productivity)="data">
          {{ `%${data.value.calculated}` }}
        </template>
        <template #cell(Queues)="data">
          {{ data.value.Value }}
        </template>
        <template #cell(Reason)="data">
          <b-badge
            :variant="
              colors.hasOwnProperty(data.value)
                ? `light-${colors[data.value]}`
                : 'light-primary'
            "
            style="width: 9em"
            >{{ data.value }}</b-badge
          >
        </template>
      </b-table>
    </b-modal>
    <b-modal
      style="min-height: 400px"
      id="modal-settings"
      :title="lang('t_settings')"
      centered
    >
      <b-form-group>
        <v-select
          @input="on_language_change"
          class="mb-3"
          v-model="selected_language"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="languages"
          :reduce="(val) => val.internal_name"
          label="display_name"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
var beep = new Audio("sounds/beep.mp3");

window.makeGuid = function (length = 16, blockSize = 16, numberBase = 16, trim = true) {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += `${(((1 + Math.random()) * blockSize) | 0).toString(numberBase).substring(1)}`;
  }
  return trim ? str.substr(0, length).toUpperCase() : str.toUpperCase();
};

import {
  BFormGroup, BFormInput, BTable, BBadge, BModal, BButtonGroup, BDropdown, BImg, BFormCheckbox, VBTooltip, BButton, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BRow,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import useAppCustomizer from './useAppCustomizer'
import { computed } from '@vue/composition-api'
import Tracker from '@openreplay/tracker';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  data() {
    return {
      all_statuses: [],
      status: [],
      selected_status: {},
      status_color: '',
      interval: {},
      statu_timer: 0,
      statu_timer_text: '00:00',
      selected_language: window.localStorage.getItem('language') || "tr",
      languages: [
        { internal_name: 'en', display_name: 'English' },
        { internal_name: 'tr', display_name: 'Türkçe' },
      ],
      lang: _l(window.localStorage.getItem('language') || "tr"),
      username: '%87',
      productivity: '%87',
      selected_button: '',
      theme_config: $themeConfig,
      qm_socket: null,
      socket: {},
      live_info: {
        QueueStatistics: new Map(),
        AgentStatistics: new Map(),
      },
      all_queues: [],
      queues: [],
      selected_queues: [],
      user_agent: null,
      is_registered: false,
      first_connect: false,
      interaction_queues: {},
      status_categories: [],
      agent_list: [],
      modal_agent_list: false,
      agent_list_fields: [
        { key: 'Username', label: globalThis._lang('t_userName'), sortable: true },
        { key: 'Reason', label: globalThis._lang('t_status'), sortable: true },
        { key: 'Time', label: globalThis._lang('t_time'), sortable: true },
        { key: 'Productivity', label: globalThis._lang('t_productivity'), sortable: true },
        { key: 'AcceptedCalls', label: globalThis._lang('t_answered'), sortable: true },
        { key: 'MissedCalls', label: globalThis._lang('t_rInboundFail'), sortable: true },
        // { key: 'AcceptedDialerCalls', label: globalThis._lang('t_rDialerSuccess'),sortable:true },
        // { key: 'MissedDialerCalls', label: globalThis._lang('t_rDialerFail'),sortable:true },
        { key: 'SuccessfulOutboundCalls', label: globalThis._lang('t_successfullCall'), sortable: true },
        { key: 'FailedOutboundcalls', label: globalThis._lang('t_unsuccessfullCall'), sortable: true },
      ],
      colors: {
        'Avail': 'success',
        'Lunch': 'danger',
        'Break': 'danger',
        'In Call - Outbound': 'warning',
        'In Call - Inbound': 'warning',
        'In Call - Dialer': 'warning',
        'ACW': 'info',
        'Ringing': 'info',
        'Offline': 'secondary',
      },
      searchQuery: '',
      other_permission: [],
      menu_list: [],
      selected_project: {},
      tmp_uniq_id: '',
      channelInterval: {},
      interval_agent_list: {}
    }
  },
  components: {
    vSelect, BFormGroup,
    BTable, BBadge, BFormInput,
    BModal,
    BButtonGroup,
    BRow,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg, BFormCheckbox,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  setup() {
    // App Name
    const { menu } = $themeConfig.layout.menu;
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()
    return {
      menu,
      skin,
      isDark,
      isNavMenuHidden
    }
  },
  methods: {
    timer(seconds) {
      let _m = ("" + (Math.floor(seconds / 60) % 60)).padStart(2, "0");
      let _h = ("" + Math.floor(seconds / 3600)).padStart(2, "0");
      let _s = ("" + Math.floor(seconds % 60)).padStart(2, "0");
      return `${_h}:${_m}:${_s}`;
    },
    beforeUnload(e) {
      const message = 'Sayfadan çıkmak istediğinize emin misiniz?';
      e.returnValue = message;
      return message;
    },
    assignee_conversation: async function (id) {
      var response = (await this.$http_in.put(`/text-channel/v1/AssigneeConversation/${id}`)).data;

      if (this.$router.currentRoute.name == 'external_chat') {
        if (globalThis.external_chat) {
          await globalThis.external_chat.get_conversations();
          globalThis.external_chat.conversation = null;
        }
      } else {
        this.selected_button = 'external_chat';
        this.$router.replace('/external_chat');
      }
    },

    on_language_change() {
      globalThis.selectLang(this.selected_language);
    },
    get_live_agent: async function () {
      // return new Promise(async (resolve, reject)=>{
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      this.agent_list = response.filter(e => e && e.Status != 'Offline');
      if (globalThis.LayoutContentRendererDefault) {
        globalThis.LayoutContentRendererDefault.online_users = response.filter(e => e && e.Status != 'Offline');
        globalThis.LayoutContentRendererDefault.chat_users = response.filter(e => e && e.Status != 'Offline').map(e => { return { nickname: `${e.Username}@${e.Domain}` } });
      }
      // console.log("agent123", this.agent_list);
      return;
      //   setTimeout(()=>{
      //     resolve();
      //   }, 300)
      // })

    },
    setAgentList: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      this.agent_list = response.filter(e => e && e.Status != 'Offline');
      for (const row of this.agent_list) {
        row.Time = Number((new Date() - new Date(row.LastActionDate)) / 1000)
      }
    },
    // register_change() {
    //   if (this.is_registered) {
    //     this.unregister();
    //   } else {
    //     this.login();
    //   }

    // },
    on_status_change(val) {
      // this.status = this.status.filter(e => e.display_name != 'Offline');
      // this.GetStatusColor(this.status_categories.find(e => e._id == val.status_category_id).internal_name);
      // this.selected_status = val;
      // this.statu_timer = 0;
      // clearInterval(this.interval);
      // this.interval = setInterval(() => {
      //   this.statu_timer += 1000;
      //   this.statu_timer_text = this.msToTimeString(this.statu_timer);
      // }, 1000);
      // console.log("on_status_change", val);

      if (val.display_name == 'Available') {
        globalThis._voyce.$qm.emit('qmgr:Unpause', {});

      }
      else {
        globalThis._voyce.$qm.emit('qmgr:Pause', {
          reason: val.display_name,
        });
      }

    },
    GetStatus: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Status`)
      ).data;

      this.status = [];
      this.all_statuses = [];
      this.status_categories = response.categories;
      globalThis.status_categories = response.categories;
      globalThis.statuses = response.status;
      let _status = globalThis.permissions["status"]
      for (const row of response.status) {
        if (_status.includes(row['_id'])) {
          this.status.push(row);
          this.all_statuses.push(row);
        }
      }
      this.selected_status = response.status.find((e) => e.display_name == 'Offline');
      // this.GetStatusColor(this.status_categories.find(e => e._id == this.selected_status.status_category_id).internal_name);
    },
    GetStatusColor(internal_name) {
      switch (internal_name) {
        case 'Available':
          this.status_color = 'outline-success';
          break;
        case 'Paused':
          this.status_color = 'outline-danger';
          break;
        case 'InCall':
          this.status_color = 'outline-warning';
          break;
        case 'InInteraction':
          this.status_color = 'outline-warning';
          break;
        case 'AgentRinging':
          this.status_color = 'outline-info';
          break;
        case 'RingingOut':
          this.status_color = 'outline-info';
          break;
        case 'ACW':
          this.status_color = 'outline-info';
          break;
        case 'Unavailable':
          this.status_color = 'outline-secondary';
          break;
        default:
          this.status_color = 'outline-primary';
          break;
      }
    },
    change_status(key, value) {
      if (key == "status_category_id") {
        this.selected_status = this.all_statuses.find((e) => e.status_category_id == value);

      }
      else if (key == "display_name") {
        this.selected_status = this.all_statuses.find((e) => e.display_name == value);

      } else {

      }

    },

    msToTimeString(ms) {
      let seconds = (ms / 1000) % 60;
      let minutes = Math.floor(ms / 1000 / 60) % 60;
      let hours = Math.floor(ms / 1000 / 60 / 60);

      minutes += hours * 60;
      seconds = ('0' + seconds).slice(-2);
      minutes = minutes > 99 ? minutes : ('0' + minutes).slice(-2);
      hours = ('0' + hours).slice(-2);

      return `${minutes}:${seconds}`;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      globalThis.qm_token = '';

      // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      location.reload();

      this.$router.push({ name: 'auth-login' })
    },
    login() {
      // console.log(globalThis.register_info);
      if (!globalThis.vSip) {
        globalThis.vSip = new VoyceSIP(`${globalThis.env["SOCKET_PROTOCOL"]}://${globalThis.env["SIP_URL"]}`, globalThis.register_info.sip_host, globalThis.register_info.sip_password);
        this.user_agent = globalThis.user_agent;
        this.user_agent.on('connecting', function (e) {
          console.log("connecting", e);
        });
        this.user_agent.on('disconnected', function (e) {
          v_navbar.is_registered = false;
          console.log("disconnected", e);
          // v_navbar.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `Uyarı`,
          //     icon: 'BellIcon',
          //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
          //     variant: 'warning',
          //   },
          // });
          if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          }
        });
        this.user_agent.on('registered', function (e) { v_navbar.is_registered = true; console.log("registered", e) });
        this.user_agent.on('unregistered', function (e) {
          v_navbar.is_registered = false;
          console.log("unregistered", e);
          // v_navbar.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `Uyarı`,
          //     icon: 'BellIcon',
          //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
          //     variant: 'warning',
          //   },
          // });
          if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          }
        });
        this.user_agent.on('registrationFailed', function (e) { v_navbar.is_registered = false; console.log("registrationFailed", e); });

      }

    },
    unregister() {
      var options = {
        all: true
      };

      this.user_agent.unregister(options);
    },
    changeMenuType() {
      // console.log("changeMenuType", this.isNavMenuHidden);
      this.isNavMenuHidden = !this.isNavMenuHidden;
    },

    GetAbandonCalls: async function () {
      var response = (
        await this.$http.post(`/LiveWatchAbandonCalls`, { "selected_queues": this.selected_queues }, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      for (const row of response) {
        let _tmp_object = {};
        if (!this.live_info.QueueStatistics.has(row.Queue)) {
          this.live_info.QueueStatistics.set(row.Queue, { CallHistory: new Map() });
        }
        _tmp_object = this.live_info.QueueStatistics.get(row.Queue);
        // console.log("test", _tmp_object);
        if (_tmp_object.CallHistory == undefined) {
          _tmp_object.CallHistory = new Map();
        }
        _tmp_object.CallHistory.set(row.Channel, row);
        _tmp_object.AbandonCounts =
          _tmp_object.AbandonCounts == undefined ? 1 : parseInt(_tmp_object.AbandonCounts) + 1;

        this.live_info.QueueStatistics.set(row.Queue, _tmp_object);
      }
    },
    formatedTimestamp: function () {
      const d = new Date();
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      return `${date} ${time}`;
    },
    GetProjects: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Project`)
      ).data;


      globalThis.projects = response;
      this.selected_project = response.find(e => e.internal_name == globalThis.user.selected_project);

    },
    GetQueues: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Queue`)
      ).data;

      this.queues = response;
      globalThis.queues = response;
    },
  },
  computed: {
    test_status() {
      return this.$store.state.app.user_state;
    }
  },
  watch: {
    test_status(newval, oldval) {
      this.change_status("display_name", newval);
      this.GetStatusColor(this.status_categories.find(e => e._id == this.selected_status.status_category_id).internal_name);
      this.statu_timer = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.statu_timer += 1000;
        this.statu_timer_text = this.msToTimeString(this.statu_timer);
      }, 1000);
    }
  },
  beforeDestroy() {
    try {
      window.removeEventListener('beforeunload', this.beforeUnload);
      // localStorage.removeItem('spvQueues');
      // localStorage.removeItem('dialer_cache');

    } catch (error) {
      console.log(error);
    }
    this.qm_socket.emit(
      'qmgr:LeaveQueue', { queues: globalThis.user.selected_queues });

    // Get a reference to the last interval + 1
    clearInterval(this.interval_agent_list);

    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    this.qm_socket.removeAllListeners();
    this.qm_socket.disconnect();
    this.qm_socket = null;
    globalThis.user_agent.stop();
  },

  mounted: async function () {
    window.addEventListener('beforeunload', this.beforeUnload);

    if (globalThis.qm_token == '') {
      this.$router.push({ name: 'auth-login' })
      return;
    }


    // console.log("globalThis.qm_token", globalThis.qm_token);
    if (this.qm_socket != null) {
      this.qm_socket.removeAllListeners();
      this.qm_socket.disconnect();
      this.qm_socket = null;
    }
    this.qm_socket = new this.$ws(`${globalThis.env["PROTOCOL"]}://${globalThis.env["GW_URL"]}`, {
      forceNew: true,
      autoConnect: false,
      extraHeaders: {
        Authorization: `Bearer ${globalThis.qm_token}`,
      },
    });

    this.qm_socket.on('System', (data) => {
      data = JSON.parse(data);
      if (data.Type == 'Error') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `İkaz`,
            icon: 'BellIcon',
            text: "Oturumunuz başka bir yerde açık olduğu için sonlandırılmıştır.",
            variant: 'warning',
          },
        })
        this.logout();
      }
    });

    this.qm_socket.connect();


    // console.log("globalThis.user.selected_queues", globalThis.user.selected_queues);
    this.qm_socket.on('connect', async () => {
      globalThis._voyce.$qm = this.qm_socket;
      console.log("qm_socket connected");
      this.first_connect = true;
      globalThis.LayoutContentRendererDefault.qm_socket_connected = true;
      this.qm_socket.emit(
        'qmgr:JoinQueue',
        { queues: globalThis.user.selected_queues }
      );

      this.get_live_agent();
      this.login();
      // this.register_change();



      globalThis.qm_status_watcher.emit("connected");
    });



    if (this.all_statuses.length == 0) {
      this.GetStatus();
      this.statu_timer = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.statu_timer += 1000;
        this.statu_timer_text = this.msToTimeString(this.statu_timer);
        // console.log(this.test_status);
      }, 1000);
    }
    this.GetProjects();
    this.GetQueues();

    this.isNavMenuHidden = true;
    let menus = ['spv_dashboard', 'goal_management', 'dialer', 'reports', 'sms', 'system_management', 'operation_management', 'settings', 'wallboard', 'dashboard', 'alarm', 'mail', 'quality', 'scheduler']
    this.menu_list = globalThis.permissions["menu"];
    for (const item of menus) {
      if (this.menu_list.includes(item)) {
        this.isNavMenuHidden = false;
        break;
      }
    }
    this.other_permission = globalThis.permissions["other"] || [];

    this.username = globalThis.username;

    window.v_navbar = this;
    this.setAgentList();
    this.interval_agent_list = setInterval(() => {
      if (window.v_navbar.modal_agent_list) {
        window.v_navbar.setAgentList();
      }
    }, 1000);

    // this.CheckPassword();
    // this.username = this.$store.state.user.username.split('@')[0];
    // this.exten = this.$store.state.agent.Exten;

    //  AgentStatusChange
    // AgentJoinedQueue
    // AgentLeftQueue
    // InteractionJoinedQueue
    // InteractionPulledBack
    // InteractionSentToAgent
    // AgentAcceptedInteraction
    // InteractionAbandoned


    this.qm_socket.on('disconnect', () => {
      console.log("qm_socket disconnect", new Date().getTime());

      // v_navbar.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: `Uyarı`,
      //     icon: 'BellIcon',
      //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
      //     variant: 'warning',
      //   },
      // });
      if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
        globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
        globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
      }

      globalThis.v_navbar.qm_socket.off("AgentAcceptedInteractionTransfer");
      globalThis.v_navbar.qm_socket.off("AgentTransferRinging");
      globalThis.v_navbar.qm_socket.off("AgentTransferredCall");
      globalThis.v_navbar.qm_socket.off("AgentAcceptedInteraction");
      globalThis.v_navbar.qm_socket.off("InteractionJoinedQueue");
      globalThis.v_navbar.qm_socket.off("InteractionAbandoned");
      globalThis.v_navbar.qm_socket.off("InteractionRemovedFromQueue");
      globalThis.v_navbar.qm_socket.off("InteractionPulledBack");
      globalThis.v_navbar.qm_socket.off("AgentLeftQueue");
      globalThis.v_navbar.qm_socket.off("AgentLostCall");
      globalThis.v_navbar.qm_socket.off("InteractionSuccessful");
      globalThis.v_navbar.qm_socket.off("InteractionSentToAgent");
      globalThis.v_navbar.qm_socket.off("TransferSentToAgent");
      globalThis.v_navbar.qm_socket.off("ClickToCall");
      globalThis.v_navbar.qm_socket.offAny();
      globalThis.LayoutContentRendererDefault.qm_socket_connected = false;

    });

    this.qm_socket.on('AgentJoinedQueue', (event) => {
      // console.log("AgentJoinedQueue", event);
      if (globalThis.spv_dashboard) {
        globalThis.spv_dashboard.AgentJoinedQueue(event);
      }
      if (globalThis.wallboard) {
        globalThis.wallboard.AgentJoinedQueue(event);
      }
      if (`${event.Username}@${event.Domain}` == globalThis.username) {

        for (const item of globalThis.user.selected_queues) {
          this.interaction_queues[item] = new Map();
        }

        this.qm_socket.on('AgentAcceptedInteractionTransfer', (data) => {

          if (data.To == globalThis.user.username) {



            if (!data.InteractionID || this.tmp_uniq_id == data.InteractionID) {
              // console.log("returning", data.InteractionID)
              return;
            }
            // console.log("continuing with the flow");
            this.tmp_uniq_id = data.InteractionID;

            globalThis.LayoutContentRendererDefault.acw = data.ACW || 10;
            globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
            // console.log("ACW", globalThis.LayoutContentRendererDefault.acw);
            if (this.selected_project.hasOwnProperty("integrations")) {
              // console.log("IF", this.selected_project.hasOwnProperty("integrations"), this.selected_project.integrations.trigger_popup);

              if (this.selected_project.integrations.trigger_popup && data.Extra.LocalNumber != '') {


                let dcs_url = `https://popup2.dcsyazilim.com/popup_v1?Key=${this.selected_project.integrations.key}&Password=${this.selected_project.integrations.password}&company_code=${this.selected_project.integrations.company_code}&caller_id=${data.Extra.RemoteNumber.substr(-11)}&did=${data.Extra.LocalNumber}&agent_id=${globalThis.user.integrations.dcs_id}&call_uid=${data.InteractionID}&queue=${data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue}&call_type=${data.Extra.Direction}&campaign=&operation=Pozitif&barcode1=brc1&barcode2=brc2&barcode3=brc3`;
                // console.log("DCS_URL", dcs_url);
                // this.$http_ex.get(dcs_url);

                this.$http_in.post(`/smgr/v1/Internal/dcs_test`, {
                  collection: `${this.selected_project.internal_name}_customers`,
                  data: {
                    ...data,
                    dcs_url
                  }
                });
              }

            }
          }
          // if (this.interaction_queues.hasOwnProperty(data.Queue)) {
          //   this.interaction_queues[data.Queue].delete(data.InteractionID);
          // }

          // if (globalThis.spv_dashboard) {
          //   globalThis.spv_dashboard.AgentAcceptedInteraction(data);
          // }
          // if (globalThis.wallboard) {
          //   globalThis.wallboard.AgentAcceptedInteraction(data);
          // }
        });
        this.qm_socket.on('AgentTransferRinging', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentTransferRinging(data);
          }
          // if (globalThis.wallboard) {
          //   globalThis.wallboard.AgentTransferRinging(data);
          // }
        })
        this.qm_socket.on('AgentTransferredCall', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentTransferredCall(data);
          }
          // if (globalThis.wallboard) {
          //   globalThis.wallboard.AgentTransferredCall(data);
          // }
        });
        this.qm_socket.on('AgentAcceptedInteraction', async (data) => {

          // console.log("AgentAcceptedInteraction AGENT", data);

          if (data.Agent == globalThis.user.username) {
            globalThis.LayoutContentRendererDefault.interaction_length = 2;


            if (data.InteractionType == 'Text') {
              await this.assignee_conversation(data.InteractionID);
              return;
            }
            if (data.Extra.Direction == 'dialer') {

            }

            if (!data.InteractionID || this.tmp_uniq_id == data.InteractionID) {
              // console.log("returning", data.InteractionID)
              return;
            }
            // console.log("continuing with the flow");
            this.tmp_uniq_id = data.InteractionID;

            globalThis.LayoutContentRendererDefault.acw = data.ACW || 10;
            globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
            // globalThis.LayoutContentRendererDefault.contact_number = data.Extra.RemoteNumber.substr(-11);
            // console.log("ACW", globalThis.LayoutContentRendererDefault.acw);
            if (this.selected_project.hasOwnProperty("integrations")) {
              // console.log("IF", this.selected_project.hasOwnProperty("integrations"), this.selected_project.integrations.trigger_popup);

              if (this.selected_project.integrations.trigger_popup && data.Extra.LocalNumber != '') {

                console.log("DCS TEST");
                let dcs_url = `https://popup2.dcsyazilim.com/popup_v1?Key=${this.selected_project.integrations.key}&Password=${this.selected_project.integrations.password}&company_code=${this.selected_project.integrations.company_code}&caller_id=${data.Extra.RemoteNumber.substr(-11)}&did=${data.Extra.LocalNumber}&agent_id=${globalThis.user.integrations.dcs_id}&call_uid=${data.InteractionID}&queue=${data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue}&call_type=${data.Extra.Direction}&campaign=&operation=Pozitif&barcode1=brc1&barcode2=brc2&barcode3=brc3`;
                // console.log("DCS_URL", dcs_url);
                // this.$http_ex.get(dcs_url);

                this.$http_in.post(`/smgr/v1/Internal/dcs_test`, {
                  collection: `${this.selected_project.internal_name}_customers`,
                  data: {
                    ...data,
                    dcs_url
                  }
                });
              }

            }
            if (data.Domain == 'nd') {
              let url = `https://www.networkdry.com/temsilci_paneli/site/getmsisdnuser/?agent=${data.Agent}&msisdn=${data.Extra.RemoteNumber}&callid=${data.InteractionID}`;
              window.open(url, '_blank');

            }
          }
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }

          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentAcceptedInteraction(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.AgentAcceptedInteraction(data);
          }
        });

        this.qm_socket.on('InteractionJoinedQueue', (data) => {
          // console.log("pano", "InteractionJoinedQueue");
          // console.log("this.interaction_queues.hasOwnProperty(data.Queue)", this.interaction_queues.hasOwnProperty(data.Queue));

          if (globalThis.user.selected_queues.includes(data.Queue)) {
            if (!this.interaction_queues.hasOwnProperty(data.Queue)) {
              this.interaction_queues[data.Queue] = new Map();
            }
            this.interaction_queues[data.Queue].set(data.InteractionID, data);
          }
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionJoinedQueue(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionJoinedQueue(data);
          }

        });
        this.qm_socket.on('InteractionAbandoned', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionAbandoned(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionAbandoned(data);
          }
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }

        });
        this.qm_socket.on('InteractionRemovedFromQueue', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionRemovedFromQueue(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionRemovedFromQueue(data);
          }
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }
        });
        this.qm_socket.on('InteractionPulledBack', (data) => {
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionPulledBack(data);
          }

        });
        this.qm_socket.on('AgentLeftQueue', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentLeftQueue(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.AgentLeftQueue(data);
          }

        });

        this.qm_socket.on('AgentLostCall', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentLostCall(data);
          }
          if (data.Username == globalThis.user.username && globalThis.LayoutContentRendererDefault.modal_incoming) {

            globalThis.LayoutContentRendererDefault.ring_audio.pause();
            globalThis.LayoutContentRendererDefault.modal_incoming = false;
          }
        });
        this.qm_socket.on('InteractionSuccessful', (data) => {
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionSuccessful(data);
          }

          if (data.Agent == globalThis.user.username) {
            console.log("8", "clearInterval");
            globalThis.LayoutContentRendererDefault.interaction_length = 1;
            // clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);


            if (data.Result == "Blacklist") {
              globalThis.LayoutContentRendererDefault.modal_finish_code = false;
              clearTimeout(this.channelInterval);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Bilgilendirme`,
                  icon: 'BellIcon',
                  text: "Aradığınız numara kara listede",
                  variant: 'warning',
                },
              })

            }
            globalThis.statisticts.get_live_agent();

            if (globalThis.crm && this.$router.currentRoute.name == 'crm' && globalThis.crm.is_selected) {
              globalThis.crm.getCustomerDetails(globalThis.crm.selected_customer);
            }
          }
        });

        this.qm_socket.on('InteractionSentToAgent', (data) => {

          console.log("InteractionSentToAgentTest");
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionSentToAgent(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionSentToAgent(data);
          }
          if (data.Agent == globalThis.user.username) {

            try {
              globalThis.LayoutContentRendererDefault.current_call_info = data;
              if (data.InteractionType == 'Text') {
                // globalThis.LayoutContentRendererDefault.selected_queue = globalThis.LayoutContentRendererDefault.current_call_info.Queue;
                globalThis.LayoutContentRendererDefault.modal_incoming = true;
                return;
              }

              if (!globalThis.LayoutContentRendererDefault.is_window_active && ['dialer', 'inbound'].includes(data.Extra.Direction)) {
                if (Notification.permission !== 'granted')
                  Notification.requestPermission();
                else {
                  var notification = new Notification('Voyce Çağrı Bildirimi', {
                    icon: 'https://live.ctcomm.voyce.cc/logo.png',
                    body: data.Extra.RemoteNumber.slice(-10),
                  });
                  // notification.onclick = function () {
                  //   if (globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] == null) {
                  //     console.log("testtt");
                  //     globalThis.LayoutContentRendererDefault.accept_interaction();
                  //   }
                  // };
                }
              }
              if (data.Extra.IvrVariables && data.Extra.IvrVariables.length > 0) {
                for (const item of data.Extra.IvrVariables) {
                  globalThis.LayoutContentRendererDefault.dtmf_list.push(`${item[0]} = ${item[1]}`);
                }

              }

              let tmp_queue = data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue;
              let __queue;
              if (data.Extra.Direction == 'outbound' || data.InteractionType == 'Chanspy') {
                globalThis.LayoutContentRendererDefault.accept_interaction();
              }
              else {
                if (tmp_queue.includes('personal:')) {
                  __queue = {
                    internal_name: data.Queue,
                    display_name: data.Queue,
                    auto_answer: false,
                  }
                } else {
                  __queue = this.queues.find(e => e.internal_name == tmp_queue);

                }

                if (data.Extra.Direction == 'dialer' && __queue && __queue.auto_answer) {
                  if (globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] != null) {
                    return;
                  }
                  globalThis.LayoutContentRendererDefault.selected_queue = globalThis.LayoutContentRendererDefault.current_call_info.Queue;
                  beep.play();
                  globalThis.LayoutContentRendererDefault.accept_interaction();
                }
                else {
                  if (globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] != null) {
                    return;
                  }
                  if (data.Extra.Direction == 'inbound' && __queue && __queue.auto_answer) {
                    globalThis.LayoutContentRendererDefault.selected_queue = globalThis.LayoutContentRendererDefault.current_call_info.Queue;
                    beep.play();
                    globalThis.LayoutContentRendererDefault.accept_interaction();
                  } else {
                    globalThis.LayoutContentRendererDefault.selected_queue = globalThis.LayoutContentRendererDefault.current_call_info.Queue;
                    globalThis.LayoutContentRendererDefault.modal_incoming = true;
                  }

                }
              }

              // globalThis.LayoutContentRendererDefault.current_call_info.Extra.Direction == 'inbound'
              if (this.other_permission.includes("crm_popup")) {
                if (this.$router.currentRoute.name == 'crm') {
                  if (globalThis.LayoutContentRendererDefault.current_call_info != null && globalThis.v_navbar.other_permission.includes("crm_popup")) {
                    if (![null, undefined, ''].includes(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId)) {
                      globalThis.crm.getCustomerContactID(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId);
                    } else {
                      globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                    }

                  }
                } else {
                  this.selected_button = 'crm';
                  this.$router.replace('/crm');
                  if (globalThis.crm) {
                    if (![null, undefined, ''].includes(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId)) {
                      globalThis.crm.getCustomerContactID(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId);
                    } else {
                      globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                    }

                  }

                }
              }

            } catch (error) {
              console.log("ERROR", error);
              globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });
              globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });

              globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
                queue: data.Queue || '',
                interactionId: data.InteractionID || '',
                verdict: 'FaultyCall',
                details: {
                  note: 'Bağlantı Hatası',
                  action_date: '',
                  last_finish_code: false,
                  color: '',
                  is_click_to_call: false,
                  finalize_reason: '',
                  error: error.message ?? ''
                },
              });

            }



          }


          // globalThis.crm.get_customer_search_term = globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10);
          // globalThis.crm.getCustomerBySearch();

        });

        this.qm_socket.on('TransferSentToAgent', (data) => {
          // console.log("InteractionSentToAgent3", data);
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.TransferSentToAgent(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.TransferSentToAgent(data);
          }
          if (data.To == globalThis.user.username) {
            try {
              globalThis.LayoutContentRendererDefault.current_call_info = data;

              if (!globalThis.LayoutContentRendererDefault.is_window_active && ['dialer', 'inbound'].includes(data.Extra.Direction)) {
                if (Notification.permission !== 'granted')
                  Notification.requestPermission();
                else {
                  var notification = new Notification('Voyce Çağrı Bildirimi', {
                    icon: 'https://live.ctcomm.voyce.cc/logo.png',
                    body: data.Extra.RemoteNumber.slice(-10),
                  });
                }
              }

              globalThis.LayoutContentRendererDefault.selected_queue = data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue;
              globalThis.LayoutContentRendererDefault.modal_transfer = true;

              if (this.other_permission.includes("crm_popup")) {
                if (this.$router.currentRoute.name == 'crm') {
                  if (globalThis.LayoutContentRendererDefault.current_call_info != null && globalThis.v_navbar.other_permission.includes("crm_popup")) {
                    if (![null, undefined, ''].includes(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId)) {
                      globalThis.crm.getCustomerContactID(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId);
                    } else {
                      globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                    }

                  }
                } else {
                  this.selected_button = 'crm';
                  this.$router.replace('/crm');
                  if (globalThis.crm) {
                    if (![null, undefined, ''].includes(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId)) {
                      globalThis.crm.getCustomerContactID(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId);
                    } else {
                      globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                    }

                  }
                }

              }

              setTimeout(() => {
                if (globalThis.LayoutContentRendererDefault.modal_transfer) {
                  globalThis.LayoutContentRendererDefault.transfer_reject_interaction();
                }
              }, 15000);

            } catch (error) {
              console.log("ERROR", error);
              globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });
              globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });

              globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
                queue: data.Queue || '',
                interactionId: data.InteractionID || '',
                verdict: 'FaultyCall',
                details: {
                  note: 'Bağlantı Hatası',
                  action_date: '',
                  last_finish_code: false,
                  color: '',
                  is_click_to_call: false,
                  finalize_reason: '',
                  error: error.message ?? ''
                },
              });

            }


          }


          // globalThis.crm.get_customer_search_term = globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10);
          // globalThis.crm.getCustomerBySearch();

        });


        this.qm_socket.on('ClickToCall', (data) => {
          if (data.Username == globalThis.user.username && globalThis.LayoutContentRendererDefault.current_call_info == null) {
            globalThis.LayoutContentRendererDefault.selected_queue = data.Queue;
            globalThis.LayoutContentRendererDefault.selected_dial_plan = data.Classification;
            globalThis.LayoutContentRendererDefault.preview_call_request(data.RemoteNumber);
            globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call = true;
          }
        });


        this.qm_socket.onAny((evt, args) => {
          console.log("catchAll", evt, args);
        });
      }




    });


    this.qm_socket.on('AgentStatusChange', (data) => {
      // console.log("AgentStatusChange AGENT", data);
      if (globalThis.spv_dashboard) {
        globalThis.spv_dashboard.AgentStatusChange(data);
      }
      if (globalThis.wallboard) {
        globalThis.wallboard.AgentStatusChange(data);
      }

      if (data.Status && data.Status == "NotInQueue") {
        return;
      }


      // this.agent_list = [...this.agent_list];
      // Vue.set(this, "agent_list", this.agent_list);
      // this.$root.$emit("bv::refresh::table", "modal_table_1");


      // var user_interval = setInterval(async () => {
      //   if (_user) {
      //     clearInterval(user_interval);
      //     _user = await (this.agent_list.find(e => e.Identifier == data.Username + "@" + data.Domain));
      //   }
      // }, 500)

      if (data.Username + "@" + data.Domain == globalThis.username) {

        if (['In Call - Outbound', 'In Call - Inbound', 'In Call - Dialer', 'In Call - Transfer'].includes(data.Reason)) {
          if (globalThis.LayoutContentRendererDefault.current_call_info) {
            globalThis.LayoutContentRendererDefault.current_call_info.is_answered = true;

          }
          console.log("IS_REGISTERED", globalThis.user_agent._registrator._registered);
        }

        if (globalThis.statisticts) {
          globalThis.statisticts.get_live_agent();
        }


        this.status = this.status.filter(e => e.display_name != 'Offline');

        const val = this.all_statuses.find(e => e.display_name == data.Reason);
        document.title = `[${this.username.split('@')[1]}] - ${data.Reason} : ${this.statu_timer_text}`
        this.GetStatusColor(data.Status);
        this.selected_status = val;
        this.statu_timer = 0;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.statu_timer += 1000;
          this.statu_timer_text = this.msToTimeString(this.statu_timer);
          document.title = `${this.username.split('@')[1]} | ${data.Reason}: ${this.statu_timer_text}`

        }, 1000);
      }
    });



    globalThis.tracker = {
      setUserID: function () {
        return;
      }
    }
    if (globalThis.env.ORLY_TOKEN) {
      globalThis.tracker = new Tracker({
        projectKey: globalThis.env.ORLY_TOKEN,
        ingestPoint: "https://log.voyce.cc/ingest",
      });
      tracker.start();
    }
    globalThis.tracker.setUserID(globalThis.username);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!')
      })
      .catch(function (err) {
        console.log('No mic for you!')
      });
  },
}
globalThis.fromBinary = function (encoded) {
  return decodeURIComponent(atob(encoded).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}
</script>
<style lang="scss">
@import "@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.dClass {
  min-width: 76rem;
}
</style>
