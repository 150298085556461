<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <slot name="breadcrumb">
      <app-breadcrumb :change_panel="change_panel" />
    </slot>
    <b-row style="height: 84.5vh; max-height: 84.5vh">
      <b-col class="pt-0 pb-0">
        <div class="content-body">
          <transition :name="routerTransition" mode="out-in">
            <slot />
          </transition>
        </div>
      </b-col>
      <!-- <transition :name="'fade'" mode="fade"> -->
      <b-col
        v-if="phone_panel_toggle || chat_panel_toggle"
        class="pt-0 pb-0"
        md="3"
        cols="12"
      >
        <b-card
          v-if="!qm_socket_connected"
          class="d-flex align-items-center justify-content-center full-height"
          style="margin: 0"
        >
          <div
            class="d-flex align-items-center justify-content-center full-height"
          >
            <b-spinner variant="primary" label="" />
            <span class="ml-1">Connecting...</span>
          </div>
        </b-card>
        <b-card
          no-body
          v-if="phone_panel_toggle && qm_socket_connected"
          style="max-heigth: 100%; height: 100%; margin: 0"
        >
          <b-row class="mt-75 px-50" align-h="between" align-v="center">
            <b-col>
              <b-dropdown
                v-if="selected_queue"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="
                  queues.find((e) => e.internal_name == selected_queue)
                    ? queues.find((e) => e.internal_name == selected_queue)
                        .display_name
                    : selected_queue
                "
                variant="flat-secondary"
              >
                <b-dropdown-item
                  @click="
                    () => {
                      selected_queue = item;
                      selected_dial_plan = '';
                    }
                  "
                  v-for="(item, i) in classification_queues"
                  :key="i"
                >
                  {{
                    queues.find((e) => e.internal_name == item)
                      ? queues.find((e) => e.internal_name == item).display_name
                      : item
                  }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col>
              <b-pagination
                class="float-right pt-1"
                v-model="selected_line"
                hide-goto-end-buttons
                :total-rows="interaction_length"
                :per-page="1"
                @change="change_line"
              />

              <!-- <b-button-group size="sm">
                <b-button
                  @click="change_line('1')"
                  pill
                  size="sm"
                  :variant="
                    selected_line == '1'
                      ? 'gradient-primary'
                      : 'gradient-secondary'
                  "
                  class="btn-icon mr-25"
                >
                  1
                </b-button>
                <b-button
                  @click="change_line('2')"
                  pill
                  size="sm"
                  :variant="
                    selected_line != '1'
                      ? 'gradient-primary'
                      : 'gradient-secondary'
                  "
                  class="btn-icon mr-25"
                >
                  2
                </b-button>
              </b-button-group> -->
            </b-col>
          </b-row>
          <div>
            <b-dropdown
              v-if="selected_queue"
              class="pl-50"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="selected_dial_plan"
              variant="flat-secondary"
            >
              <b-dropdown-item
                @click="
                  () => {
                    selected_dial_plan = item.name;
                    is_international_call = item.international_call || false;
                  }
                "
                v-for="(item, i) in classifications.filter(
                  (e) =>
                    e.queue ==
                    (queues.find((j) => j.internal_name == selected_queue)
                      ? queues.find((j) => j.internal_name == selected_queue)
                          .internal_name
                      : '')
                )"
                :key="i"
              >
                {{ `${item.name}` }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mt-3 mb-1">
            <!-- <v-select
              :options="dial_plans"
              :placeholder="lang('t_dialPlan')"
              label="classification"
            >
            </v-select> -->
          </div>
          <div class="mx-5">
            <b-form-input
              :readonly="interaction[selected_line] != null"
              v-on:keydown.enter.prevent="call_request"
              style="font-size: 28px; opacity: 0.8"
              class="text-center font-weight-bolder form-control-lg round"
              v-model="contact_number"
            ></b-form-input>
          </div>
          <div class="mt-1 text-center mb-50">
            <span
              v-if="call_timer > 0"
              class="text-center font-weight-lighter"
              >{{ call_timer_text }}</span
            >
            <span v-else class="text-center font-weight-lighter">{{
              last_call_event
            }}</span>
          </div>
          <div class="text-center">
            <b-row
              style="max-width: 280px; min-width: 280px; display: inline-flex"
              class="mt-1"
            >
              <b-col class="px-0">
                <b-button
                  @click="write_phone('1')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >1
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; margin-auto; opacity : 0"
                      class="text-center font-weight-bold"
                      >AAA</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('2')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >2
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >ABC</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('3')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >3
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >DEF</span
                    >
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-row
              style="max-width: 280px; min-width: 280px; display: inline-flex"
              class="mt-1"
            >
              <b-col class="px-0">
                <b-button
                  @click="write_phone('4')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >4
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; margin-auto"
                      class="text-center font-weight-bold"
                      >GHI</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('5')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >5
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >JKL</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('6')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >6
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >MNO</span
                    >
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-row
              style="max-width: 280px; min-width: 280px; display: inline-flex"
              class="mt-1"
            >
              <b-col class="px-0">
                <b-button
                  @click="write_phone('7')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >7
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; margin-auto"
                      class="text-center font-weight-bold"
                      >PRS</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('8')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >8
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >TUV</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('9')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >9
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >XYZ</span
                    >
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-row
              style="max-width: 280px; min-width: 280px; display: inline-flex"
              class="mt-1"
            >
              <b-col class="px-0">
                <b-button
                  @click="write_phone('*')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >✻
                    </span>
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('0')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >0
                    </span>
                    <br />
                    <span
                      style="font-size: 10px; width: 5em"
                      class="text-center font-weight-bold"
                      >+</span
                    >
                  </div>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  @click="write_phone('#')"
                  variant="outline-secondary"
                  class="rounded-circle"
                  style="width: 5em; height: 5em"
                >
                  <div>
                    <span
                      style="font-size: 28px"
                      class="text-center font-weight-bold"
                      >#
                    </span>
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-row
              style="max-width: 280px; min-width: 280px; display: inline-flex"
              class="mt-1"
            >
              <b-button
                v-if="interaction[selected_line] != null"
                @click="hangup"
                variant="gradient-danger"
                class="rounded-circle m-auto"
                style="width: 5em; height: 5em"
              >
                <feather-icon size="24" icon="PhoneOffIcon" />
              </b-button>
              <b-button
                v-else
                @click="call_request"
                variant="gradient-success"
                class="rounded-circle m-auto"
                style="width: 5em; height: 5em"
              >
                <feather-icon size="24" icon="PhoneIcon" />
              </b-button>
            </b-row>
          </div>
          <div class="text-center">
            <b-row
              align-h="center"
              style="max-width: 20vh; min-width: 20vh; display: inline-flex"
              class="text-center mt-5"
            >
              <b-col
                class="px-0"
                v-if="
                  dtmf_list.length > 0 &&
                  other_permission.includes('show_ivr_dial')
                "
              >
                <b-button
                  variant="primary"
                  block
                  @click="modal_dtmf_list = true"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="lang('t_showInputs')"
                  class="rounded-circle btn-icon"
                  :style="{
                    width: '44px',
                    height: '44px',
                    opacity: interaction[selected_line] == null ? 0.4 : 1,
                  }"
                >
                  <feather-icon icon="EyeIcon"></feather-icon>
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  :disabled="interaction[selected_line] == null"
                  @click="mute"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isMuted().audio
                        ? lang('t_microphoneOn')
                        : lang('t_microphoneOff')
                      : lang('t_microphoneOff')
                  "
                  :variant="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isMuted().audio
                        ? 'secondary'
                        : 'outline-secondary'
                      : 'outline-secondary'
                  "
                  class="rounded-circle btn-icon"
                  :style="{
                    width: '44px',
                    height: '44px',
                    opacity: interaction[selected_line] == null ? 0.4 : 1,
                  }"
                >
                  <feather-icon
                    :icon="
                      interaction[selected_line] != null
                        ? interaction[selected_line].isMuted().audio
                          ? 'MicIcon'
                          : 'MicOffIcon'
                        : 'MicOffIcon'
                    "
                  />
                </b-button>
              </b-col>
              <b-col class="px-0">
                <b-button
                  :disabled="interaction[selected_line] == null"
                  @click="hold"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isOnHold().local
                        ? lang('t_continue')
                        : lang('t_hold')
                      : lang('t_hold')
                  "
                  :variant="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isOnHold().local
                        ? 'secondary'
                        : 'outline-secondary'
                      : 'outline-secondary'
                  "
                  class="rounded-circle btn-icon"
                  :style="{
                    width: '44px',
                    height: '44px',
                    opacity: interaction[selected_line] == null ? 0.4 : 1,
                  }"
                >
                  <feather-icon
                    :icon="
                      interaction[selected_line] != null
                        ? interaction[selected_line].isOnHold().local
                          ? 'PlayIcon'
                          : 'PauseIcon'
                        : 'PauseIcon'
                    "
                  />
                </b-button>
              </b-col>
              <b-col v-if="selected_line != 2" class="px-0">
                <b-button
                  @click="open_transfer_modal"
                  :disabled="interaction[selected_line] == null"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="lang('t_transfer')"
                  :variant="
                    interaction[selected_line] != null
                      ? 'outline-secondary'
                      : 'outline-secondary'
                  "
                  class="rounded-circle btn-icon"
                  :style="{
                    width: '44px',
                    height: '44px',
                    opacity: interaction[selected_line] == null ? 0.4 : 1,
                  }"
                >
                  <feather-icon icon="PhoneForwardedIcon" />
                </b-button>
              </b-col>
              <b-col
                v-if="
                  selected_line != 2 &&
                  interaction[selected_line] != null &&
                  dtmf_list.find((e) => e.includes('Anket'))
                "
                class="px-0"
              >
                <b-button
                  :disabled="interaction[selected_line] == null"
                  @click="set_survey_state"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                      ? lang('t_surveyCancel')
                      : lang('t_surveyApprove')
                  "
                  :variant="
                    dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                      ? 'secondary'
                      : 'outline-secondary'
                  "
                  class="rounded-circle btn-icon"
                  :style="{
                    width: '44px',
                    height: '44px',
                    opacity: interaction[selected_line] == null ? 0.4 : 1,
                  }"
                >
                  <feather-icon
                    :icon="
                      dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                        ? 'LayoutIcon'
                        : 'LayoutIcon'
                    "
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card
          v-if="chat_panel_toggle && qm_socket_connected"
          no-body
          style="max-heigth: 100%; height: 100%; margin: 0"
          class="ma-0"
        >
          <b-row class="mx-2 mt-1" align-h="between" align-v="center">
            <span>{{ lang("t_chatPanel") }}</span>
            <b-button-group size="sm">
              <!-- <b-button
                  @click="panels.anons = true"
                  size="sm"
                  variant="flat-primary"
                  class="btn-icon mr-25 rounded-circle"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Anons'"
                >
                  <feather-icon
                    class="pa-0 ma-0"
                    size="12"
                    icon="Volume2Icon"
                  ></feather-icon>
                </b-button>
                <b-button
                  @click="panels.sufle = true"
                  size="sm"
                  variant="flat-primary"
                  class="btn-icon mr-25 rounded-circle"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Sufle'"
                >
                  <feather-icon
                    class="pa-0 ma-0"
                    size="12"
                    icon="BellIcon"
                  ></feather-icon>
                </b-button> -->
              <b-button
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_startChat')"
                variant="primary"
                class="btn-icon rounded-circle"
                @click="panels.new_chat = true"
              >
                <feather-icon
                  class="pa-0 ma-0"
                  size="12"
                  icon="PlusIcon"
                ></feather-icon>
              </b-button>
            </b-button-group>
          </b-row>
          <div>
            <b-tabs fill v-if="chatpanel == false">
              <b-tab :title="lang('t_conversations')">
                <!-- username != chat._id.messages[0].From
                        ? chat._id.messages[0].From
                        : chat._id.messages[0].To -->
                <div
                  v-for="(chat, i) in recent"
                  :key="i"
                  @click="continueNewChat(chat)"
                  class="cursor-pointer my-50 mx-50 employee-task d-flex justify-content-between align-items-center"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar
                        class="badge-glow"
                        :variant="getChatColor(chat)"
                        badge-variant="info"
                      >
                        {{
                          (username != chat._id.messages[0].From
                            ? chat._id.messages[0].From
                            : chat._id.messages[0].To
                          )
                            .charAt(0)
                            .toUpperCase()
                        }}
                        <template
                          v-if="
                            userUnRead.hasOwnProperty(
                              chat._id.messages[0].From
                            ) && userUnRead[chat._id.messages[0].From] > 0
                          "
                          #badge
                        >
                        </template>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        {{
                          username != chat._id.messages[0].From
                            ? chat._id.messages[0].From
                            : chat._id.messages[0].To
                        }}
                      </h6>
                      <small
                        style="
                          display: inline-block;
                          width: 8vw;
                          white-space: nowrap;
                          overflow: hidden !important;
                          text-overflow: ellipsis;
                        "
                        >{{ chat._id.messages[0].msg }}</small
                      >
                    </b-media-body>
                  </b-media>
                </div>
              </b-tab>
              <b-tab :title="lang('t_announcementHistory')">
                <div style="max-height: 70vh; overflow-y: auto">
                  <div v-for="(item, i) in announcements" :key="i">
                    <b-card
                      no-body
                      border-variant="primary"
                      class="mx-50 mb-50"
                    >
                      <b-card-text
                        @click="showAnnounceDetails(item)"
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="lang('t_showDetail')"
                        class="cursor-pointer"
                      >
                        <div class="mx-50">
                          <small class="font-weight-bolder">
                            {{
                              item.sender.includes("@")
                                ? item.sender.split("@")[0]
                                : item.sender
                            }}
                            -
                            {{ msgTime(item.announcements.Timestamp) }}
                          </small>
                        </div>
                        <div class="mx-50">
                          <small
                            style="
                              display: inline-block;
                              width: 8vw;
                              white-space: nowrap;
                              overflow: hidden !important;
                              text-overflow: ellipsis;
                            "
                            >{{ item.announcements.msg }}</small
                          >
                        </div>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="mt-3 chat-panel-container" v-else>
              <div class="chat_panel_inner">
                <b-container class="bg-primary mb-1 mt-1" fluid>
                  <b-row align-h="between" align-v="center">
                    <b-button
                      size="sm"
                      variant="primary"
                      class="btn-rounded"
                      @click="closeChatPanel()"
                    >
                      <feather-icon
                        class="light-text"
                        size="14"
                        icon="ArrowLeftIcon"
                      />
                    </b-button>
                    <b-badge variant="primary">
                      {{
                        active_chat.username.includes("@")
                          ? active_chat.username.split("@")[0]
                          : active_chat.username
                      }}</b-badge
                    >
                  </b-row>
                </b-container>

                <div id="msg_div" class="messages">
                  <div
                    class="msg"
                    v-for="(value, key) in active_chat.messages"
                    :key="key"
                  >
                    <span
                      :class="
                        value.to == active_chat.username ? 'msg_to' : 'msg_from'
                      "
                      >{{ value.message }}
                      <small class="time"> {{ msgTime(value.time) }} </small>
                    </span>
                  </div>
                </div>
                <div class="mt-1">
                  <b-input-group>
                    <b-form-input
                      ref="msg"
                      v-model="message"
                      @keyup.enter="sendMsg()"
                      :placeholder="lang('t_yourMessage')"
                    ></b-form-input>
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="SendIcon"
                        class="cursor-pointer"
                        @click="sendMsg"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-- <b-col class="ma-0 pa-0" cols="1">
                      <b-button variant="btn-icon" @click="sendMsg()">
                        <feather-icon size="12" icon="SendIcon" />
                      </b-button>
                    </b-col> -->
      <!-- </transition> -->
    </b-row>
    <b-modal
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_announcementPanel')"
      v-model="panels.anons"
    >
      <b-form-textarea
        v-model="announcement_message"
        :placeholder="lang('t_yourMessage')"
        rows="3"
      >
      </b-form-textarea>
    </b-modal>
    <b-modal
      static
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      :ok-title="lang('t_save')"
      @ok="save_finish_code"
      v-model="modal_finish_code"
    >
      <template #modal-title>
        <code>{{ lang("t_remainingTime") }} : {{ acw_timer }}</code>
        <hr v-if="!other_permission.includes('mask_remote_number')" />
        <a
          href="#"
          @click="doCopy(`${current_call_info.Extra.RemoteNumber.slice(-10)}`)"
          v-if="
            current_call_info &&
            !other_permission.includes('mask_remote_number')
          "
        >
          {{ `${current_call_info.Extra.RemoteNumber.slice(-10)}` }}</a
        >
        <a
          href="#"
          @click="doCopy(current_call_info.customer_no)"
          v-if="
            current_call_info &&
            current_call_info.hasOwnProperty('customer_no') &&
            !other_permission.includes('mask_remote_number')
          "
        >
          {{ `- ${current_call_info.customer_no}` }}</a
        >
        <!-- <h5 v-if="current_call_info">
          {{ `Telefon: ${current_call_info.Extra.RemoteNumber.slice(-10)}` }}
        </h5>
        <h5
          v-if="
            current_call_info && current_call_info.hasOwnProperty('customer_no')
          "
        >
          {{ `Müşteri No: ${current_call_info.customer_no}` }}
        </h5> -->
      </template>
      <b-form-group>
        <v-select
          v-model="interaction_finish_code"
          :options="
            other_permission.includes('show_all_finish_codes')
              ? finish_codes
              : finish_codes.filter(
                  (e) =>
                    e.is_reached ==
                    (current_call_info == null
                      ? false
                      : current_call_info.is_answered || false)
                )
          "
          :reduce="(val) => val"
          :placeholder="lang('t_finishCode')"
          label="finish_code"
          @input="on_finish_code_change"
        >
        </v-select>
      </b-form-group>
      <b-form-group v-if="interaction_finish_code.require_date">
        <flat-pickr
          v-model="interaction_action_date"
          class="form-control"
          title="Aksiyon Tarihi"
          placeholder="Aksiyon Tarihi"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group v-if="interaction_finish_code.save_calendar">
        <b-row align-h="center">
          <b-button
            class="mr-1"
            style="height: 5vh"
            size="lg"
            v-for="(item, i) in cal_colors"
            :key="i"
            @click="interaction_color = item.color"
            :variant="item.color"
          >
            <feather-icon
              size="18"
              v-if="interaction_color == item.color"
              icon="CheckIcon"
            ></feather-icon>
          </b-button>
        </b-row>
      </b-form-group>
      <b-form-group>
        <b-form-textarea
          v-model="interaction_note"
          :placeholder="lang('t_note')"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
      <b-form-group v-if="other_permission.includes('set_is_last_finish_code')">
        <b-form-checkbox
          v-model="interaction_finish_code.is_last_finish_code"
          class="custom-control-primary"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
              {{ lang("t_lastFinishCode") }}
            </span>
          </span>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="after_call_break"
          class="custom-control-primary"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
              {{ lang("t_changeStatusAfterTheCall") }}
            </span>
          </span>
        </b-form-checkbox>
        <v-select
          class="mt-1"
          v-if="after_call_break"
          v-model="after_call_break_status"
          :options="
            status.filter(
              (e) =>
                e.changeable &&
                !['Available', 'Offline'].includes(e.display_name)
            )
          "
          :reduce="(val) => val.display_name"
          :placeholder="lang('t_status')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <!-- <b-form-group>
        <b-row align-h="between">
          <b-col>
            <b-form-checkbox
              v-model="interaction_last_finish_code"
              class="custom-control-primary"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                  {{ lang("t_finish_code") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox class="custom-control-primary">
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                  {{ lang("t_survey") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-group> -->
    </b-modal>
    <b-modal
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_extraTime')"
      centered
      v-model="modal_extra_time"
      :ok-title="lang('t_ok')"
      ok-only
      @ok="extra_time_ok"
      hide-header-close
    >
      <center>
        <h2>{{ extra_time_counter }}</h2>
      </center>
    </b-modal>
    <b-modal
      static
      style="z-index: 999; position: absolute"
      size="sm"
      v-if="![undefined, null, ''].includes(current_call_info)"
      no-close-on-esc
      no-close-on-backdrop
      :title="getQueueName()"
      centered
      v-model="modal_incoming"
      :ok-title="lang('t_answer')"
      :cancel-title="lang('t_reject')"
      @ok="accept_interaction"
      @cancel="reject_interaction"
      ok-variant="success"
      cancel-variant="outline-danger"
      hide-header-close
      @show="phone_ring_start"
      @close="phone_ring_stop"
      @hide="phone_ring_stop"
    >
      <b-container>
        <b-row>
          {{
            `${current_call_info.Extra.CallerIDName} - ${
              current_call_info.InteractionType != "Text"
                ? other_permission.includes("mask_remote_number")
                  ? maskPhoneNumber(
                      current_call_info.Extra.RemoteNumber.substr(-11)
                    )
                  : current_call_info.Extra.RemoteNumber.substr(-11)
                : other_permission.includes("mask_remote_number")
                ? maskPhoneNumber(current_call_info.Extra.RemoteNumber)
                : current_call_info.Extra.RemoteNumber
            }`
          }}
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      static
      style="z-index: 999; position: absolute"
      size="sm"
      v-if="![undefined, null, ''].includes(current_call_info)"
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_transfer')"
      centered
      v-model="modal_transfer"
      :ok-title="lang('t_answer')"
      :cancel-title="lang('t_reject')"
      @ok="transfer_accept_interaction"
      @cancel="transfer_reject_interaction"
      ok-variant="success"
      cancel-variant="outline-danger"
      hide-header-close
      @show="phone_ring_start"
      @close="phone_ring_stop"
      @hide="phone_ring_stop"
    >
      <b-container>
        <b-row>
          {{
            `${
              current_call_info.Extra.CallerIDName
            } - ${current_call_info.Extra.RemoteNumber.substr(-11)}`
          }}
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      static
      :title="lang('t_agentList')"
      hide-footer
      scrollable
      centered
      v-model="modal_agent_list"
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        style="max-height: 50vh; overflow-y: auto"
        class="mt-1"
        :items="agent_list"
        :fields="agent_list_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'Username'"
      >
        <template #cell(Queues)="data">
          {{ data.value.Value }}
        </template>
        <template #cell(Reason)="data">
          <b-badge
            :variant="
              colors.hasOwnProperty(data.value) ? colors[data.value] : 'primary'
            "
            style="width: 9em"
            >{{ data.value }}</b-badge
          >
        </template>
        <template #cell(actions)="">
          <b-button size="sm" @click="null" variant="outline-primary">
            <feather-icon icon="SendIcon" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      size="lg"
      :title="lang('t_transfer')"
      hide-footer
      scrollable
      centered
      v-model="modal_transfer_list"
    >
      <b-tabs fill>
        <b-tab
          @click="transfer_tab_changed(item)"
          v-for="(item, i) in transfer_tabs"
          :title="item.display_name"
          :key="i"
        ></b-tab>
      </b-tabs>
      <div
        class="mt-3"
        style="max-height: 50vh; height: 50vh; overflow-y: auto"
        v-if="selected_transfer_tab == 'External'"
      >
        <b-form-input
          trim
          v-model="external_transfer.number"
          placeholder="Numara"
        />
        <v-select
          class="mt-1"
          v-model="external_transfer.line_group"
          :options="line_groups"
          :placeholder="lang('t_lineGroup')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
        <b-button
          class="mt-1"
          block
          v-b-tooltip.hover.v-primary
          v-b-tooltip.hover.bottom="lang('t_transfer')"
          @click="null"
          variant="outline-primary"
        >
          <feather-icon icon="SendIcon" />
          {{ lang("t_transfer") }}
        </b-button>
      </div>
      <div
        class="mt-3"
        style="max-height: 50vh; height: 50vh; overflow-y: auto"
        v-else
      >
        <div>
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            :placeholder="lang('t_search')"
          />
        </div>
        <b-table
          style="max-height: 50vh; overflow-y: auto"
          class="mt-1"
          :items="selected_transfer_tab == 'Agent' ? agent_list : transfer_list"
          :fields="
            selected_transfer_tab == 'Agent'
              ? agent_list_fields
              : transfer_list_fields
          "
          ref="table"
          striped
          reactive
          responsive="sm"
          :filter="searchQuery"
          :sortBy="
            selected_transfer_tab == 'Agent' ? 'Username' : 'display_name'
          "
        >
          <template #cell(Reason)="data">
            <b-badge
              :variant="
                colors.hasOwnProperty(data.value)
                  ? colors[data.value]
                  : 'primary'
              "
              style="width: 9em"
              >{{ data.value }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <b-button
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_transfer')"
              size="sm"
              @click="
                transfer_interaction(
                  selected_transfer_tab == 'Agent'
                    ? data.item.Username
                    : data.item.internal_name
                )
              "
              variant="outline-primary"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal
      static
      :title="lang('t_agentList')"
      hide-footer
      scrollable
      centered
      v-model="panels.new_chat"
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        class="mt-1"
        :items="chat_users"
        :fields="chat_users_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'nickname'"
      >
        <template #cell(nickname)="data">
          {{ data.value.split("@")[0] }}
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            @click="startNewChat(data.item.nickname)"
            variant="outline-primary"
          >
            <feather-icon icon="SendIcon" />
            {{ lang("t_startChat") }}
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      :title="lang('t_inputList')"
      ok-only
      scrollable
      centered
      v-model="modal_dtmf_list"
    >
      <b-row v-for="(item, i) in dtmf_list" :key="i">
        <span style="font-size: 1.2rem" class="mx-1">
          {{ `${i + 1}) ${item}` }}
        </span>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BDropdown, BPagination, BTable, BSpinner,
  BDropdownDivider,
  BDropdownItem, BFormCheckbox, BFormGroup, BFormTextarea, BModal, BCardText, BInputGroup, BInputGroupAppend, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, VBTooltip, BFormInput, BButton, BButtonGroup, BRow, BCol, BCard, BTabs, BTab, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { $themeColors } from '@themeConfig'
import 'animate.css'
import flatPickr from 'vue-flatpickr-component'
var audio = new Audio("sounds/ring.mp3");
var hangup = new Audio("sounds/hangup.mp3");

audio.loop = true;
import CalendarNotification from "./CalendarNotification.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      is_window_active: true,
      username: '',
      finish_codes: [],
      queues: [],
      dial_plans: [],
      classifications: [],
      classification_queues: [],
      transfer_tabs: [
        { internal_name: 'Agent', display_name: globalThis._lang("t_agent"), },
        { internal_name: 'Queue', display_name: globalThis._lang("t_queue"), },
        { internal_name: 'IVR', display_name: globalThis._lang("t_IVR"), },
        { internal_name: 'vIVR', display_name: globalThis._lang("t_vIVR"), },
        { internal_name: 'pbIVR', display_name: globalThis._lang("t_phoneBook"), },
        // { internal_name: 'External', display_name: 'Dış Numara' },
      ],
      selected_transfer_tab: 'Agent',
      selected_queue: '',
      selected_dial_plan: '',
      selected_line: '1',
      selected_panel: 'softphone',
      contact_number: '',
      phone_panel_toggle: true,
      chat_panel_toggle: false,
      modal_finish_code: false,
      modal_extra_time: false,
      modal_dtmf_list: false,
      modal_transfer: false,
      modal_incoming: false,
      extra_time_counter: 0,
      interaction_length: 1,
      interaction: {
        "1": null,
        "2": null,
      },
      dtmf_number: '',
      dtmf_controller: {},
      last_call_event: '',
      interval: null,
      call_timer: 0,
      call_timer_text: '00:00',
      //CHAT
      tab: null,
      tab_items: ["Konuşmalar", "Anons Geçmişi"],
      souffle_agent: "",
      souffle_message: "",
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      souffle_snackbar: false,
      souffle_snackbarMessage: "",
      souffle_snackbarTitle: "",
      souffle_snackbarColor: "",
      chatpanel: false,
      search_chat_list: "",
      maximize: false,
      announcement_message: "",
      message: "",
      chat_users: [],
      userUnRead: {},
      totalUnRead: 0,
      online_users: [],
      anonsment_detail: false,
      anonsment_detail_content: {
        sender: "",
        time: "",
        msg: "",
      },
      active_chat: {
        username: "",
        user_from: "",
        user_to: "",
        messages: [],
      },
      suffles: [
        {
          from: "Şahin Ersever",
          date: "22.05.2021 14:45",
          message: "Test geçmiş sufle çalışmaya devam!",
        },
        {
          from: "Burak Ersever",
          date: "22.05.2021 14:45",
          message: "Test geçmiş sufle çalışmaya devam! 2",
        },
      ],
      announcements: [],
      classifications_id_list: [],
      panels: {
        anons: false,
        sufle: false,
        new_chat: false,
      },
      recent: [],
      status: [],
      acw: 0,
      acw_timer: 0,
      transfer_interval: null,
      acw_interval: null,
      extra_time_interval: null,
      interaction_finish_code: '',
      interaction_action_date: '',
      interaction_note: '',
      interaction_color: 'primary',
      interaction_last_finish_code: false,
      socket: '',
      boxTwo: false,
      current_call_info: null,
      cal_colors: [
        {
          color: 'danger',
          label: 'Personal',
        },
        {
          color: 'primary',
          label: 'Business',
        },
        {
          color: 'warning',
          label: 'Family',
        },
        {
          color: 'success',
          label: 'Holiday',
        },
        {
          color: 'info',
          label: 'ETC',
        },
      ],
      modal_agent_list: false,
      modal_transfer_list: false,
      searchQuery: '',
      transfer_list: [],
      transfer_list_fields: [
        { key: 'display_name', label: globalThis._lang("t_displayName"), },
        { key: 'actions', label: globalThis._lang("t_action"), },
      ],
      agent_list: [],
      agent_list_fields: [
        { key: 'Username', label: globalThis._lang("t_agent"), },
        { key: 'Reason', label: globalThis._lang("t_status"), },
        { key: 'actions', label: globalThis._lang("t_action"), },
      ],
      chat_users_fields: [
        { key: 'nickname', label: globalThis._lang("t_agent"), },
        { key: 'actions', label: globalThis._lang("t_action"), },
      ],
      colors: {
        'Avail': 'success',
        'Lunch': 'danger',
        'Break': 'danger',
        'In Call - Outbound': 'warning',
        'In Call - Inbound': 'warning',
        'In Call - Dialer': 'warning',
        'ACW': 'info',
        'Ringing': 'info',
        'Offline': 'secondary',
      },
      events: [],
      calendarInterval: {},
      ring_audio: null,
      old_call_status: '',
      qm_socket_connected: false,
      after_call_break: false,
      after_call_break_status: '',
      is_international_call: false,
      dtmf_list: [],
      tmp_dtmf_string: [],
      dtmf_interval: null,
      other_permission: [],
      transfer_targets: {
        Queue: [],
        Agent: [],
        IVR: [],
        vIVR: [],
        pbIVR: [],
      },
      external_transfer: {
        number: '',
        line_group: {}
      },
      line_groups: [],
      toast_limit: 0,
      FinishCodesByQueue: new Map()
    }
  },
  computed: {

    maskPhoneNumber() {
      return globalThis.maskPhoneNumber; // globalThis üzerinden global değişkene erişim
    }
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    CalendarNotification, BSpinner,
    BDropdown, BPagination, BTable,
    BDropdownDivider,
    BDropdownItem, BFormCheckbox, BFormGroup, flatPickr, BFormTextarea, BModal, BCardText, BInputGroup, BInputGroupAppend, BContainer, BAvatar, BFormInput, AppBreadcrumb, BRow, BCol, BCard, BButton, BButtonGroup, vSelect, BTabs, BTab, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge
  },
  methods: {
    getQueueName() {
      let tmp_queue = this.current_call_info.Extra.Direction == 'outbound'
        ? this.current_call_info.Extra.Queue
        : this.current_call_info.Queue;

      let _queue = queues.find(e => e.internal_name == tmp_queue);

      if (_queue) {
        return _queue.display_name;
      } else {
        return tmp_queue;
      }

    },
    set_survey_state() {
      globalThis._voyce.$qm.emit('qmgr:MarkInteractionForSurvey', {
        interactionId: this.current_call_info.InteractionID,
      });

      let result = this.dtmf_list.find(e => e.includes("Anket"));
      console.log("result", result)

      if (result) {
        if (result.includes("Evet")) {
          result = result.replace("Evet", "Hayır");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Anket İptal Edildi.',
              icon: 'BellIcon',
            },
          })
        } else {
          result = result.replace(result.split("=")[1], "Evet");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Anket Onaylandı.',
              icon: 'BellIcon',
            },
          })

        }
      }
      let index = this.dtmf_list.findIndex(e => e.includes("Anket"));
      console.log("index", index);
      this.dtmf_list.splice(index, 1, result);
      // console.log("result",  = result;)
    },
    async SetIvrLineGroup(lg, ivr) {
      var response = (
        await this.$http_in.put(`/agent/v1/IVR/${ivr}`, { LineGroup: lg })
      ).data;
    },

    async transfer_interaction(internal_name) {
      let _self = this;
      if (this.selected_transfer_tab == 'External') {

      } else if (this.selected_transfer_tab == 'vIVR') {
        globalThis._voyce.$qm.emit('qmgr:StartVIVR', {
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name
        });
      }
      else if (this.selected_transfer_tab == 'pbIVR') {

        let lg_name = '';
        if (this.selected_dial_plan != '') {
          lg_name = this.line_groups.find(e => e.internal_name == this.dial_plans.find(j => j.queue == this.selected_queue && j.classification == this.selected_dial_plan).line_group);

        } else {
          lg_name = this.line_groups.find(e => e.internal_name == this.dial_plans.find(j => j.queue == this.selected_queue).line_group);
        }

        await this.SetIvrLineGroup(lg_name, internal_name);

        globalThis._voyce.$qm.emit('qmgr:SendToIVROrQueue', {
          target_type: 'IVR',
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name
        });
      }
      else if (this.selected_transfer_tab == 'Agent') {
        globalThis._voyce.$qm.emit('qmgr:TransferInteraction', {
          target_type: this.selected_transfer_tab,
          interactionId: this.current_call_info.InteractionID,
          toWhom: internal_name
        });
      }
      else {
        globalThis._voyce.$qm.emit('qmgr:SendToIVROrQueue', {
          target_type: this.selected_transfer_tab,
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name
        });
        // setTimeout(() => {
        //   _self.hangup();
        // }, 500);
      }

      this.modal_transfer_list = false;


    },
    transfer_tab_changed(item) {
      console.log(item);
      this.selected_transfer_tab = item.internal_name;
      switch (item.internal_name) {
        case 'External':

          break;
        case 'Agent':
          globalThis.v_navbar.get_live_agent();
          this.agent_list = globalThis.v_navbar.agent_list;
          break;
        default:
          this.transfer_list = this.transfer_targets[item.internal_name];
          break;
      }
    },
    // preventNav(event) {
    //   if (globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] != null) {
    //     globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
    //       queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
    //       interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
    //     });

    //     globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
    //       queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue,
    //       interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
    //       verdict: 'İşlem Yapılmadı',
    //       details: {
    //         note: 'Çağrıda esnasında Voyce sayfası kapatıldı',
    //         action_date: '',
    //         last_finish_code: false,
    //         color: '',
    //         is_click_to_call: globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call ? globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call : false,
    //         finalize_reason: ''
    //       },
    //     });
    //   }
    //   event.preventDefault()
    //   event.returnValue = ""
    // },
    async doCopy(text) {
      await navigator.clipboard.writeText(text);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Metin kopyalandı.',
          icon: 'BellIcon',
        },
      })
    },
    getChatColor(chat) {
      return this.online_users.find(e => e.Status != 'Offline' &&
        `${e.Username}@${e.Domain}` == (globalThis.username != chat._id.messages[0].From
          ? chat._id.messages[0].From
          : chat._id.messages[0].To)
      ) != undefined
        ? 'success'
        : 'secondary'
    },

    open_transfer_modal() {
      globalThis.v_navbar.get_live_agent();
      this.agent_list = globalThis.v_navbar.agent_list;
      this.selected_transfer_tab = 'Agent';
      this.modal_transfer_list = true;
    },
    on_finish_code_change() {
      if (this.other_permission.includes('set_is_last_finish_code')) {
        this.interaction_finish_code.is_last_finish_code = false;
      }
      // console.log("on_finish_code_change", this.interaction_finish_code);
      // this.interaction_last_finish_code = this.interaction_finish_code.is_last_finish_code;
    },
    phone_ring_start() {
      // console.log("phone_ring_start");
      audio.play();
    },
    phone_ring_stop() {
      // console.log("phone_ring_stop");
      audio.pause();
      audio.currentTime = 0;
    },
    accept_interaction() {
      console.log(this.current_call_info);
      globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
      });
      this.modal_incoming = false;
    },
    reject_interaction() {
      globalThis._voyce.$qm.emit('qmgr:RejectInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
        reason: 'Break',
      });
    },
    transfer_accept_interaction() {
      console.log(this.current_call_info);
      globalThis._voyce.$qm.emit('qmgr:AcceptTransfer', {
        interactionId: this.current_call_info.InteractionID,
      });
      this.modal_transfer = false;
    },
    transfer_reject_interaction() {
      globalThis._voyce.$qm.emit('qmgr:RejectTransfer', {
        interactionId: this.current_call_info.InteractionID,
        reason: 'Break',
      });
      this.modal_transfer = false;
    },
    extra_time_ok() {
      clearInterval(this.extra_time_interval);
      globalThis._voyce.$qm.emit('qmgr:Unpause', {});
    },

    save_finish_code(bvModalEvent) {
      if (this.interaction_finish_code == '') {

        this.$bvToast.toast(`Sonuç kodu boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }
      clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
      if (this.interaction_finish_code.require_date && this.interaction_action_date == '') {

        this.$bvToast.toast(`Aksiyon tarihi boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }

      globalThis.statisticts.ch_data.push({
        insert_date: new Date(new Date().getTime() + 1000 * 60 * 60 * 3).toISOString().slice(11, 19),
        contact_number: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(this.current_call_info.Extra?.RemoteNumber.slice(-10)) : this.current_call_info.Extra?.RemoteNumber.slice(-10),
        finish_code: this.interaction_finish_code.finish_code,
        queuename: this.current_call_info.Extra.Direction == 'outbound' ? this.current_call_info.Extra.Queue : this.current_call_info.Queue,
        call_direction: this.current_call_info.Extra.Direction,
        note: this.interaction_note || '',
        RecordingFile: globalThis.v_navbar.other_permission.includes(
          "agent_display_sound"
        )
          ? this.current_call_info.Extra.RecordingFile
          : "",
      });



      // console.log("interaction_finish_code", this.interaction_finish_code);
      if (this.interaction_finish_code.is_extra_time) {
        this.modal_extra_time = true;
        // this.$store.commit("app/updateUserState", this.status.find(e => e._id == this.interaction_finish_code.extra_time_status).display_name);
        this.extra_time_counter = Number(this.interaction_finish_code.extra_time_amount) || 0;
        this.extra_time_interval = setInterval(() => {
          this.extra_time_counter--;
          if (this.extra_time_counter == 0) {
            this.modal_extra_time = false;
            clearInterval(this.extra_time_interval);
          }
        }, 1000);
      }



      globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
        verdict: this.interaction_finish_code.finish_code,
        details: {
          note: this.interaction_note,
          action_date: this.interaction_action_date,
          last_finish_code: this.interaction_last_finish_code,
          finish_code: { ...this.interaction_finish_code, extra_time_status_name: (this.interaction_finish_code.extra_time_status == '' ? '' : this.status.find(e => e._id == this.interaction_finish_code.extra_time_status).display_name) },
          color: this.interaction_color,
          is_click_to_call: this.current_call_info.is_click_to_call ? this.current_call_info.is_click_to_call : false,
          finalize_reason: this.after_call_break ? this.after_call_break_status : ''
        },
        raw: this.current_call_info
      });

      this.after_call_break = false;
      this.after_call_break_status = '';

      this.current_call_info = null;

      if (this.interaction_finish_code.save_calendar) {
        setTimeout(() => {
          globalThis.LayoutContentRendererDefault.getEvents();
        }, 10000);
      }


    },
    get_queues: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Queue`)
      ).data;
      this.queues = [];
      this.queues.push({ internal_name: 'undefined', display_name: 'Tanımsız' });

      this.queues = [...this.queues, ...response];
      //
    },
    GetTransferTargets: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/TransferTargets`)
      ).data;

      this.transfer_targets = {
        Queue: response.Queues,
        Agent: response.LocalUsers,
        IVR: response.Ivr,
        vIVR: response.V_Ivr,
        pbIVR: response.PB_Ivr,
      };
      this.line_groups = response.LineGroups;
    },
    GetStatus: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Status`)
      ).data;

      this.status = response.status;
    },

    get_dial_plans: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/DialPlan`)
      ).data;

      this.dial_plans = response;
      //
    },
    get_classifications: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Classification`)
      ).data;
      this.classifications = [];
      this.classifications_id_list = globalThis.permissions['dial_plan'];
      for (const item of response) {
        if (this.classifications_id_list.includes(item._id)) {
          this.classifications.push(item);
        }
      }


      ;
      // this.classification_queues = [...new Set([this.classifications.map(e => e.queue)])];
      // console.log("classification_queues", this.classification_queues);

      this.classification_queues = Array.from(new Set(this.classifications.map(e => e.queue)));
      // this.selected_queue = this.classification_queues[0];
      this.selected_queue = globalThis.user.selected_queues[0];
      // console.log("classification_queues", this.classification_queues);
      // this.selected_dial_plan = this.classifications.length > 0 ? (this.classifications[0].name || '') : '';

    },
    write_phone(value) {
      if (this.interaction[this.selected_line] != null) {
        this.contact_number += value;
        this.dtmf_number += value;
        clearTimeout(this.dtmf_controller);
        this.dtmf_controller = setTimeout(() => {
          this.interaction[this.selected_line].sendDTMF(this.dtmf_number);
          this.dtmf_number = '';
        }, 1500);
      } else {
        this.contact_number += value;
      }
    },
    change_line() {

      let old_line = this.selected_line;
      let new_line = this.selected_line == 1 ? 2 : 1;
      if (this.interaction[old_line] != null && !this.interaction[old_line].isOnHold().local) {
        // console.log("this.interaction[old_line]", this.interaction[old_line]._remote_identity._uri._user);
        this.interaction[old_line].hold();
      }

      this.contact_number = '';
      if (this.interaction[new_line] != null) {
        this.contact_number = this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(this.interaction[new_line]._remote_identity._uri._user) : this.interaction[new_line]._remote_identity._uri._user;
        this.interaction[new_line].hold();
      }
    },
    call_request() {
      if (globalThis.v_navbar.other_permission.includes('verify_call')) {
        this.$swal({
          title: this.queues.find((e) => e.internal_name == this.selected_queue).display_name + " - " + this.selected_dial_plan,
          text: `[${this.selected_dial_plan}] hattından çıkış yapılacak, onaylıyor musunuz?`,
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.call();
          }
        })
      }
      else {
        this.call();
      }
    },
    preview_call_request(phone, contact_id = '') {
      if (globalThis.v_navbar.other_permission.includes('verify_call')) {
        this.$swal({
          title: this.queues.find((e) => e.internal_name == this.selected_queue).display_name + " - " + this.selected_dial_plan,
          text: `[${this.selected_dial_plan}] hattından çıkış yapılacak, onaylıyor musunuz?`,
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.preview_call(phone, contact_id);
          }
        })
      }
      else {
        this.preview_call(phone, contact_id);
      }
    },
    call() {
      console.log(this.contact_number);

      if (this.selected_queue == '' || this.selected_dial_plan == '') {
        this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectQueueAndClassification")}`, {
          title: `${globalThis._lang("t_warning")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        });
        return;
      }

      if (this.interaction[this.selected_line] == null) {
        this.contact_number = this.contact_number.split(' ').join('').replace(/\D+/g, '');

        if (this.selected_line == 2) {
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = globalThis.vSip.Call(`second_line_${this.contact_number}`);
        }
        else {
          globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;
          this.interaction[this.selected_line] = globalThis.vSip.Call(this.contact_number, [`X-RemoteNumber: ${this.contact_number}`, `X-InternationalCall: ${this.is_international_call ? 'international' : 'national'}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
        }

      }


      // globalThis._voyce.$qm.emit('qmgr:StartOutboundInteraction', {
      //   Queue: this.selected_queue,
      //   Classification: this.selected_dial_plan,
      //   RemoteNumber: this.contact_number.slice(-10),
      // });

      // globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = {
      //   isMuted() {
      //     return {
      //       audio: false
      //     }
      //   },
      //   isOnHold() { return { local: false } },
      //   terminate() { return; }
      // };

      // globalThis.outboundcallsuccesstimer = setTimeout(() => {
      //   //
      //   globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
      //   globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
      //     queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue,
      //     interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
      //   });
      // }, 10000)
    },
    preview_call(phone, contact_id = '') {



      if (this.selected_queue == '' || this.selected_dial_plan == '') {
        this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectQueueAndClassification")}`, {
          title: `${globalThis._lang("t_warning")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        });
        return;
      }
      if (this.interaction[this.selected_line] == null) {


        if (this.selected_line == 2) {
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = globalThis.vSip.Call(`second_line_${phone.split(' ').join('').replace(/\D+/g, '')}`);
        }
        else {
          globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;
          this.interaction[this.selected_line] = globalThis.vSip.Call(phone.split(' ').join('').replace(/\D+/g, ''), [`X-RemoteNumber: ${phone.split(' ').join('').replace(/\D+/g, '')}`, `X-InternationalCall: ${this.is_international_call ? 'international' : 'national'}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`, `X-ContactID: ${contact_id}`]);
        }

        if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
          this.contact_number = globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
        } else {
          this.contact_number = phone.split(' ').join('').replace(/\D+/g, '');
        }

      }

      // if (this.interaction[this.selected_line] == null) {
      //   this.contact_number = phone.split(' ').join('').replace(/\D+/g, '');

      //   globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;

      //   this.interaction[this.selected_line] = globalThis.vSip.Call(phone, [`X-RemoteNumber: ${phone}`, `X-InternationalCall: ${this.is_international_call ? 'international' : 'national'}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
      // }
    },
    hangup() {
      // console.log("this.selected_line", this.selected_line);
      this.interaction[this.selected_line].terminate();
      this.interaction[this.selected_line] = null;
    },
    mute() {
      if (this.interaction[this.selected_line].isMuted().audio) {
        this.interaction[this.selected_line].unmute({ audio: true });
      }
      else {
        this.interaction[this.selected_line].mute({ audio: true });
      }
    },
    hold() {
      if (this.interaction[this.selected_line] != null) {
        if (!this.interaction[this.selected_line].isOnHold().local) {
          this.interaction[this.selected_line].hold();
        }
        else {
          this.interaction[this.selected_line].unhold();
        }
      }

    },
    showAnnounceDetails(item) {
      this.$swal({
        title: item.sender.includes("@")
          ? item.sender.split("@")[0] + " " + this.msgTime(item.announcements.Timestamp)
          : item.sender + " " + this.msgTime(item.announcements.Timestamp),
        html: item.announcements.msg,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      })
    },
    change_panel(type) {
      if (type == 'softphone') {
        this.chat_panel_toggle = false;

        if (this.phone_panel_toggle) {
          this.phone_panel_toggle = false;
        }
        else {
          this.phone_panel_toggle = true;
        }
      }
      else {
        this.phone_panel_toggle = false;
        if (this.chat_panel_toggle) {
          this.chat_panel_toggle = false;
        }
        else {
          this.chat_panel_toggle = true;
        }
      }
      // console.log(type);
    },
    // msgTime(UNIX_timestamp) {
    //   var a = new Date(UNIX_timestamp);
    //   var year = a.getFullYear();
    //   var month = a.getMonth();
    //   var date = a.getDate();
    //   var hour = a.getHours();
    //   var min = a.getMinutes();
    //   var time = date + "." + month + "." + year + " " + hour + ":" + min;
    //   return time;
    // },
    msToTimeString(ms) {
      let seconds = (ms / 1000) % 60;
      let minutes = Math.floor(ms / 1000 / 60) % 60;
      let hours = Math.floor(ms / 1000 / 60 / 60);

      minutes += hours * 60;
      seconds = ('0' + seconds).slice(-2);
      minutes = minutes > 99 ? minutes : ('0' + minutes).slice(-2);
      hours = ('0' + hours).slice(-2);

      return `${minutes}:${seconds}`;
    },
    showAnonsmentDetail(sender, time, msg) {
      this.anonsment_detail = true;
      this.anonsment_detail_content = {
        sender: sender,
        time: this.msgTime(time),
        msg: msg,
      };
    },
    closeChatPanel() {
      if (this.chatpanel == true) {
        this.chatpanel = false;
        this.active_chat = {
          username: "",
          user_from: "",
          user_to: "",
          messages: [],
        };
      } else {
        this.chatpanel = true;
      }
      // this.chatpanel = !this.chatpanel;
    },
    msgTime(t) {
      let date_ob = new Date(t);
      // var day = ("0" + date_ob.getDate()).slice(-2);
      // var month = ("0" + date_ob.getMonth() +1).slice(-2);
      // var year = date_ob.getFullYear();

      // var hours = ("0" + date_ob.getHours()).slice(-2);
      // var minutes = ("0" + date_ob.getMinutes()).slice(-2);

      // return `${day}.${month}.${year} ${hours}:${minutes}`;
      return new Date(date_ob.getTime() + (3 * 60 * 60 * 1000)).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2');
    },

    closeEmoji() {
      this.emoji_open = true;
      this.emoji_open = false;
    },
    selectedEmoji(args) {
      this.message = this.message + args.emoji;
      this.$refs.msg.focus();
    },
    scrollToBottom: () => {
      var scr = document.querySelector("#msg_div");
      if (![null, undefined].includes(scr)) {
        scr.scrollTop = scr.scrollHeight + 100;
      }
    },
    GetFinishCodes: async function () {
      let tmp_FinishCodesByQueue = new Map();

      var response = (
        await this.$http_in.get(`agent/v1/FinishCodes`)
      ).data;
      // this.finish_codes = response.finish_codes;

      // for (const queue of globalThis.user.selected_queues) {
      //   let _queue = this.queues.find(e => e.internal_name == queue);
      //   if (_queue.finish_codes && _queue.finish_codes.length > 0) {
      //     // for (const finish_code of _queue.finish_codes) {
      //     // }
      //     tmp_FinishCodesByQueue.set(queue, _queue.finish_codes);

      //   }
      //   else {
      //     let fc = globalThis.permissions["finish_code"]
      //     let tmp_array = [];

      //     for (const row of response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code)) {
      //       // console.log(row);
      //       if (fc.includes(row['finish_code'])) {
      //         tmp_array.push(row['finish_code']);
      //       }
      //       tmp_FinishCodesByQueue.set(queue, tmp_array);

      //     }
      //   }
      // }

      // console.log("tmp_FinishCodesByQueue", tmp_FinishCodesByQueue);
      // for (const __queue of tmp_FinishCodesByQueue.keys()) {
      //   console.log("__queue", __queue);
      //   let c_fc = [];
      //   for (const __fc of tmp_FinishCodesByQueue.get(__queue)) {
      //     let o_fc = response.finish_codes.find(e => e.finish_code == __fc);
      //     if (o_fc) {
      //       c_fc.push(o_fc);
      //     }
      //   }
      //   this.FinishCodesByQueue.set(__queue, c_fc);

      // }

      // console.log(Array.from(calculated_fc));
      let fc = globalThis.permissions["finish_code"]
      // console.log("this.$store.state.privileges", this.$store.state.privileges);
      for (const row of response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code)) {
        // console.log(row);
        if (fc.includes(row['finish_code'])) {
          this.finish_codes.push(row);
        }
      }

      // for (const _fc of Array.from(calculated_fc)) {
      //   let _tmp = response.finish_codes.find(e => e.finish_code == _fc)
      //   let _cond = this.finish_codes.find(e => e.finish_code == _fc)
      //   if (!_cond && _tmp) {
      //     this.finish_codes.push(_tmp);
      //   }
      // }
      // this.interaction_finish_code = this.finish_codes[0];

      // console.log("this.finish_codes", this.finish_codes);

      // this.finish_codes = response.finish_codes;
      globalThis.finish_codes = response.finish_codes;
      globalThis.finish_categories = response.finish_categories;
    },
    startNewChat: async function (nickname) {
      console.log("nickname", nickname);
      this.active_chat.messages = [];
      this.active_chat.username = nickname;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = nickname;
      this.chatpanel = true;
      this.panels.new_chat = false;
      let increaseCouter = this.userUnRead[nickname]
        ? this.userUnRead[nickname]
        : 0;
      this.totalUnRead = this.totalUnRead - increaseCouter;
      this.userUnRead[nickname] = null;
      this.userUnRead = { ...this.userUnRead };
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { sender: nickname });
      globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", { nickname });

      // await this.socket.emit("GetUnreadFrom", nickname);
      // await this.socket.emit("AllUsersMessageList", nickname);
      // this.$refs.msg.focus();
      //MESAJLAR BURADA ÇEKİLECEK MANUEL BOŞALTIYORUM ŞUAN İÇİNİ
      this.active_chat.messages = [];
      this.message = "";
    },
    continueNewChat: async function (chat) {
      // console.log("nickname",nickname);
      var nickname = globalThis.username != chat._id.messages[0].From ? chat._id.messages[0].From : chat._id.messages[0].To;
      console.log("nickname", nickname);
      this.active_chat.messages = [];
      this.active_chat.username = nickname;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = nickname;
      this.chatpanel = true;
      this.panels.new_chat = false;
      let increaseCouter = this.userUnRead[nickname]
        ? this.userUnRead[nickname]
        : 0;
      this.totalUnRead = this.totalUnRead - increaseCouter;
      this.userUnRead[nickname] = null;
      this.userUnRead = { ...this.userUnRead };
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { nickname });
      globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", { nickname });

      // await this.socket.emit("GetUnreadFrom", nickname);
      // await this.socket.emit("AllUsersMessageList", nickname);
      // this.$refs.msg.focus();
      //MESAJLAR BURADA ÇEKİLECEK MANUEL BOŞALTIYORUM ŞUAN İÇİNİ
      this.active_chat.messages = [];
      this.message = "";
    },

    sendMsg() {
      if (
        this.message.trim() == "" ||
        this.active_chat.user_to == "" ||
        this.active_chat.user_from == ""
      ) {
        this.message = "";
        this.$refs.msg.focus();
      } else {
        globalThis._voyce.$qm.emit("Chat:ChatMessage", {
          target: this.active_chat.user_to,
          message: this.message,
        });

        this.active_chat.messages.push({
          to: this.active_chat.user_to,
          from: this.active_chat.user_from,
          message: this.message,
          time: new Date().getTime(),
        });
        this.message = "";
        this.$refs.msg.focus();
        this.scrollToBottom();
      }
    },


    getEvents: async function () {
      var response = (
        await this.$http_in.get(`/agent/v1/Calendar/Today`)
      ).data;
      this.events = [];
      // const merge_events = [].concat(response[0], response[1]);
      const merge_events = response;
      for (const item of merge_events) {
        this.events.push({
          id: item._id,
          title: item.finishcode,
          start: item.start,
          end: item.end,
          note: item.note,
          contact_number: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(item.contact_number) : item.contact_number,
          color: item.color,
        });
      }
    },
    async showCalendarPopup(item) {



      var response = (
        await this.$http_in.get(`/crm/v1/CustomersByPhone/${item.contact_number.slice(-10)}`)
      ).data;


      this.$toast.success({
        component: CalendarNotification,
        props: {
          verdict: `${item.title} (${item.start.split(' ')[1]})`,
          remoteNumber: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(item.contact_number.slice(-10)) : item.contact_number.slice(-10),
          note: item.note,
          customer_name: response.result.length > 0 ? (response.result[0].hasOwnProperty('Adı Soyadı') ? response.result[0]["Adı Soyadı"] : '') : '',
          customer_no: response.result.length > 0 ? (response.result[0].hasOwnProperty('Müşteri No') ? response.result[0]["Müşteri No"] : '') : '',
        },
        listeners: {
          myClick: () => { this.preview_call_request(item.contact_number.slice(-10)); }
        }
      }, {
        onClose: () => {
          console.log('Toast message has been closed.');
          this.toast_limit--;
          this.getEvents();
        },
        position: "bottom-left",
        timeout: 30000000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.76,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        closeButtonClassName: "close-button",
        toastClassName: "toast-property",
        maxToasts: 3,
        // icon: {
        //   iconClass: "",
        //   iconChildren: "",
        //   iconTag: "i"
        // },
        rtl: false
      });
    },
    setEvents: async function (item) {
      var response = (
        await this.$http_in.put(`/agent/v1/Calendar/Status`, { EventInfo: item })
      ).data;
    },
  },
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav)
  // },

  mounted: async function () {
    globalThis.LayoutContentRendererDefault = this;
    this.other_permission = globalThis.permissions["other"] || [];
    window.addEventListener('focus', () => {
      // console.log("focus");
      globalThis.LayoutContentRendererDefault.is_window_active = true;
    });
    window.addEventListener('blur', () => {
      // console.log('blue');
      globalThis.LayoutContentRendererDefault.is_window_active = false;
    });

    this.username = globalThis.username;
    this.ring_audio = audio;
    await this.get_queues();

    await this.GetFinishCodes();
    await this.get_classifications();
    await this.get_dial_plans();
    await this.GetStatus();
    await this.getEvents();
    await this.GetTransferTargets();


    // Promise.all([this.GetFinishCodes()
    //   , this.get_queues()
    //   , this.get_classifications()
    //   , this.get_dial_plans()
    //   , this.GetStatus()
    // ]);
    this.transfer_interval = setInterval(() => {
      if (globalThis.v_navbar && globalThis.LayoutContentRendererDefault.modal_transfer_list && globalThis.LayoutContentRendererDefault.selected_transfer_tab == 'Agent') {
        globalThis.LayoutContentRendererDefault.agent_list = globalThis.v_navbar.agent_list;
      }
    }, 1000);
    this.calendarInterval = setInterval(() => {
      for (const item of this.events) {
        const _date = new Date();
        // console.log("CC", (_date - new Date(item.start)) / 1000 / 60);
        if ((new Date(item.start) - _date) / 1000 / 60 <= 5) {

          if (this.toast_limit > 2) {
            continue;
          }
          globalThis.LayoutContentRendererDefault.toast_limit++;

          globalThis.LayoutContentRendererDefault.showCalendarPopup(item);
          globalThis.LayoutContentRendererDefault.setEvents(item);
          globalThis.LayoutContentRendererDefault.events = globalThis.LayoutContentRendererDefault.events.filter(e => e != item);
        }
      }
    }, 5000);



    // if (globalThis.v_navbar && !globalThis.v_navbar.is_registered) {
    //   globalThis.v_navbar.register_change();

    // }
    // globalThis.username = 'ersever@voyce';


    // console.log("globalThis._voyce.$qm", globalThis._voyce.$qm);


    globalThis.qm_status_watcher.on("connected", () => {
      console.log("qm_socket_connected", "connected")
      globalThis.LayoutContentRendererDefault.qm_socket_connected = true;
    })
    globalThis.qm_status_watcher.on("disconnect", () => {
      console.log("qm_socket_connected", "disconnect")
      globalThis.LayoutContentRendererDefault.qm_socket_connected = false;
    })


    globalThis.vSip.RegisterHandlers({
      'progress': function (e) {
        console.log('call is in progressaaaa OUTBOUND');
      },
      'failed': function (e) {
        // console.log('call failed with cause3:  OUTBOUND', e);
      },
      'ended': function (e) {
        try {
          if (globalThis.session && globalThis.session.isEnded()) {
            globalThis.LayoutContentRendererDefault.interaction[2] = null;
            globalThis.session = null;
            return;
          } else {
            if (globalThis.LayoutContentRendererDefault.interaction[2]) {
              globalThis.LayoutContentRendererDefault.interaction[2].terminate();
            }
          }
        } catch (error) {
          console.log(error);
        }


        hangup.play();
        globalThis.LayoutContentRendererDefault.dtmf_list = [];
        globalThis.LayoutContentRendererDefault.modal_dtmf_list = false;
        globalThis.LayoutContentRendererDefault.tmp_dtmf_string = [];
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.contact_number = '';

          clearInterval(globalThis.LayoutContentRendererDefault.interval);

          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";
          globalThis.LayoutContentRendererDefault.after_call_break = false;
          globalThis.LayoutContentRendererDefault.after_call_break_status = '';
          globalThis.LayoutContentRendererDefault.modal_finish_code = true;
          // console.log("ACW TIMEOUT", globalThis.LayoutContentRendererDefault.acw_timer);
          console.log("5", "setInterval");

          globalThis.LayoutContentRendererDefault.acw_interval = setInterval(() => {
            globalThis.LayoutContentRendererDefault.acw_timer--;
            if (globalThis.LayoutContentRendererDefault.acw_timer <= 0) {
              console.log("5", "clearInterval");

              clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);

              globalThis.LayoutContentRendererDefault.modal_finish_code = false;
              globalThis.LayoutContentRendererDefault.current_call_info = null;
              globalThis.statisticts.get_ch();
            }
          }, 1000);

          globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
            queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
            interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
          });
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
        } else {
          globalThis.LayoutContentRendererDefault.contact_number = '';
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;

          if (globalThis.LayoutContentRendererDefault.old_call_status == 'Available') {
            globalThis._voyce.$qm.emit('qmgr:Unpause', {});

          }
          else {
            globalThis._voyce.$qm.emit('qmgr:Pause', {
              reason: globalThis.LayoutContentRendererDefault.old_call_status,
            });
          }
        }

        globalThis.LayoutContentRendererDefault.selected_line = 1;
      },
      'confirmed': function (e) {
        console.log('call confirmed OUTBOUND');
      },
      'peerconnection': function (e) {
        console.log("peerconnection", this.selected_line);
        console.log("peerconnection", e);
        if (globalThis.LayoutContentRendererDefault.selected_line != 2) {
          globalThis._voyce.$qm.emit('qmgr:Pause', {
            reason: 'Calling',
          });
        }
      },

      'connecting': function (e) {
        console.log(globalThis.LayoutContentRendererDefault.old_call_status);
        console.log('call accepted connecting');

      },
      'accepted': function (e) {
        console.log('call accepted OUTBOUND');
      },
    });
    globalThis.user_agent.on("newMessage", (e) => {
      console.log("newMessage", e);
      let _self = this;
      if ([null, undefined, ''].includes(e.request.body)) {
        e.request.body = e.request.headers.Body[0].raw;
      }
      if (e.request.body.startsWith("vivr:")) {
        console.log(`newMessage && e.request.body`, e.request.body);
        if (e.request.body.includes('##')) {
          for (const item of e.request.body.split(":")[1].split('##')) {
            _self.dtmf_list.push(item);
          }
        } else {
          _self.dtmf_list.push(e.request.body.split(":")[1]);
        }
        // console.log("this.dtmf_list", this.dtmf_list);
      }


      //  if (e.request.body.startsWith("dtmf:")) {
      //   // console.log(`Gotten DTMF, key: ${e.request.body.split(':')[1]}, channel:${e.request.from.uri.user}`);
      //   clearTimeout(this.dtmf_interval);
      //   this.dtmf_interval = setTimeout(() => {
      //     if (_self.tmp_dtmf_string.length > 0) {
      //       _self.dtmf_list.push(_self.tmp_dtmf_string.join(''));
      //       _self.tmp_dtmf_string = [];
      //     }
      //     clearTimeout(_self.dtmf_interval);

      //   }, 4000);

      //   if (e.request.body.split(':')[1] == '#') {
      //     if (_self.tmp_dtmf_string.length > 0) {
      //       _self.dtmf_list.push(_self.tmp_dtmf_string.join(''));
      //       _self.tmp_dtmf_string = [];
      //     }
      //     clearTimeout(this.dtmf_interval);
      //   }
      //   else if (e.request.body.split(':')[1] == '*') {
      //     this.tmp_dtmf_string = [];
      //   }
      //   else {
      //     _self.tmp_dtmf_string.push(e.request.body.split(':')[1])
      //   }
      //  }

    });
    globalThis.user_agent.on("newRTCSession", function (e) {


      // console.log("newRTCSession", e);
      var session = e.session; //
      if (globalThis.LayoutContentRendererDefault.selected_line == 2 && globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] == null) {
        globalThis.session = session;
      } var callOptions = {
        mediaConstraints: {
          audio: true,
          video: true
        }
      };
      globalThis.LayoutContentRendererDefault.interaction_finish_code = '';
      globalThis.LayoutContentRendererDefault.interaction_action_date = '';
      globalThis.LayoutContentRendererDefault.interaction_color = 'primary';
      globalThis.LayoutContentRendererDefault.interaction_note = '';
      clearTimeout(globalThis.outboundcallsuccesstimer);

      // console.log("USER AGENT", globalThis.user_agent);
      if (session._direction != 'outgoing') {
        session.on("accepted", function (e) {
          // the call has answered
          // console.log("accepted CALL", e);

          // console.log("accepted", "the call has answered", e);


        });
        session.on("confirmed", function (e) {
          console.log("ibound_confirmed", e);
          clearTimeout(globalThis.LayoutContentRendererDefault.channelInterval);
          audio.pause();
          audio.currentTime = 0;
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = e.ack.ua._sessions[e.ack.call_id + e.ack.from_tag];

          globalThis.LayoutContentRendererDefault.contact_number = globalThis.LayoutContentRendererDefault.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line]._remote_identity._uri._user.slice(-10)) : globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line]._remote_identity._uri._user.slice(-10);
          // globalThis.LayoutContentRendererDefault.$store.commit("app/updateUserState", "Çağrıda Gelen");
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.interval = setInterval(() => {
            globalThis.LayoutContentRendererDefault.call_timer += 1000;
            globalThis.LayoutContentRendererDefault.call_timer_text = globalThis.LayoutContentRendererDefault.msToTimeString(globalThis.LayoutContentRendererDefault.call_timer);
          }, 1000);
        });

        session.answer(callOptions);


        session.on("ended", function (e) {
          try {
            if (globalThis.session && globalThis.session.isEnded()) {
              globalThis.LayoutContentRendererDefault.interaction[2] = null;
              globalThis.session = null;
              return;
            } else {
              if (globalThis.LayoutContentRendererDefault.interaction[2]) {
                globalThis.LayoutContentRendererDefault.interaction[2].terminate();
              }
            }
          } catch (error) {
            console.log(error)
          }

          globalThis.LayoutContentRendererDefault.dtmf_list = [];
          globalThis.LayoutContentRendererDefault.modal_dtmf_list = false;
          globalThis.LayoutContentRendererDefault.tmp_dtmf_string = [];
          globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
            queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
            interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
          });

          hangup.play();

          audio.pause();
          audio.currentTime = 0;
          // console.log("ended", "the call has ended");

          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;

          globalThis.LayoutContentRendererDefault.contact_number = '';

          clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
          clearInterval(globalThis.LayoutContentRendererDefault.interval);

          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw ?? 10;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";
          globalThis.LayoutContentRendererDefault.after_call_break = false;
          globalThis.LayoutContentRendererDefault.after_call_break_status = '';
          globalThis.LayoutContentRendererDefault.modal_finish_code = true;
          // console.log("ACW TIMEOUT", globalThis.LayoutContentRendererDefault.acw_timer);
          console.log("3", "setInterval");

          globalThis.LayoutContentRendererDefault.acw_interval = setInterval(() => {
            globalThis.LayoutContentRendererDefault.acw_timer--;
            if (globalThis.LayoutContentRendererDefault.acw_timer <= 0) {
              // Zaman aşımı girilecek...
              console.log("4", "clearInterval");

              clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
              globalThis.LayoutContentRendererDefault.modal_finish_code = false;
            }
          }, 1000);

          globalThis.LayoutContentRendererDefault.selected_line = 1;
        });
        session.on("failed", function () {
          console.log("failed", "unable to establish the call");
          audio.pause();
          audio.currentTime = 0;
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          globalThis.LayoutContentRendererDefault.contact_number = '';
          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";

          // unable to establish the call
        });



        // let resp = session.terminate();

        session.connection.addEventListener('addstream', (e) => {
          var audio = document.createElement('audio');
          audio.srcObject = e.stream;
          audio.play();
        })
      }


    })
    // WEBHOOK

    globalThis._voyce.$qm.on("WebhookTrigger", async (data) => {
      console.log("WebhookTrigger", data);
      window.open(data.Url, '_blank');
    });
    globalThis._voyce.$qm.on("WebhookNotification", async (data) => {
      console.log("WebhookNotification", data);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.Action,
          icon: 'BellIcon',
          text: data.Message,
          variant: data.Variant,
        },
      },
        {
          position: data.Position,
          timeout: 36000000,
          toastClassName: "toast-property-warning",
        });
    });

    // EXTERNAL CHAT
    globalThis._voyce.$qm.on("ExternalChatMessageCreated", async (data) => {
      console.log("ExternalChatMessageCreated", data);
      if (globalThis.external_chat && globalThis.external_chat.conversation) {
        globalThis.external_chat.conversation.messages.push(data.payload);
        // globalThis.external_chat.get_conversation_details(globalThis.external_chat.conversation.id);
        globalThis.external_chat.scrollToBottom();
      }
    });
    globalThis._voyce.$qm.on("ExternalChatConversationCreated", async (data) => {
      console.log("ExternalChatConversationCreated", data);
      if (globalThis.external_chat) {
        globalThis.external_chat.get_conversations();
      }
    });


    // INTERNAL CHAT
    globalThis._voyce.$qm.on("OfflineMessages", async (data) => {
      console.log("offlineMessages", data);
      console.log("recentdata", this.recent);

      for (let val of data) {
        this.totalUnRead += val.count;
        globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", val._id);
        if (this.userUnRead.hasOwnProperty(val._id)) {
          this.userUnRead[val._id] += val.count;
          this.userUnRead = { ...this.userUnRead };
        } else {
          this.userUnRead[val._id] = val.count;
          this.userUnRead = { ...this.userUnRead };
        }
      }
    });

    globalThis._voyce.$qm.emit("Chat:ChatRegister", {
      username: globalThis.username
    });

    globalThis._voyce.$qm.emit("Chat:GetLastMessages", {
      username: globalThis.username,
    });

    globalThis._voyce.$qm.emit("Chat:GetAnonsmentHistory", {
      username: globalThis.username,
    });
    globalThis._voyce.$qm.on("AnonsmentHistoryResponse", (data) => {
      console.log("AnonsmentHistoryResponse", data);
      this.announcements = data.result;
    });
    globalThis._voyce.$qm.emit("Chat:GetSuffleHistory", {
      username: globalThis.username,
    });

    globalThis._voyce.$qm.on("LastMessagesResponse", (data) => {
      if (data.lastMessages.length > 0) {
        data.lastMessages = data.lastMessages.sort(function (x, y) {
          return y._id.messages[0].Timestamp - x._id.messages[0].Timestamp;
        });
      }

      this.recent = [...data.lastMessages];
      // console.log(this.recent);
    });


    globalThis._voyce.$qm.on("ChatMessagesError", (data) => {
      console.log("ChatMessagesError", data);
    });
    globalThis._voyce.$qm.on("AllUsersMessageListResponse", (data) => {
      for (let arg of data.topTenMessage.reverse()) {
        this.active_chat.messages.push({
          to: arg.messages.To,
          from: arg.messages.From,
          message: arg.messages.msg,
          time: arg.messages.Timestamp,
        });
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    });


    globalThis._voyce.$qm.on("Alert", async (data) => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: globalThis._lang('t_alert') + ": " + data.Target,
          icon: 'BellIcon',
          text: data.Message,
          variant: 'warning',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          toastClassName: "toast-property-warning",
        });
    });

    globalThis._voyce.$qm.on("offlineMessages", (data) => {
      console.log("offlineMessages", data);
    });
    globalThis._voyce.$qm.on("ChatRegisterAdd", (data) => {
      console.log("ChatRegisterAdd", data);
    });

    globalThis._voyce.$qm.on("ChatMessageResponse", async (data) => {
      console.log("ChatMessageResponse", data);
      // this.active_chat.messages = [];
      // this.message = "";
      console.log(data);
      if (LayoutContentRendererDefault.chatpanel == false) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Yeni Mesaj [${data.sender.split('@')[0]}]`,
            icon: 'BellIcon',
            text: data.message.substr(0, 24),
            variant: 'info',
          },
        })
      }

      globalThis._voyce.$qm.emit("Chat:GetLastMessages", {
        username: globalThis.username,
      });

      if (this.active_chat.user_to != data.sender) {
        this.totalUnRead += 1;
        if (this.userUnRead.hasOwnProperty(data.sender)) {
          this.userUnRead[data.sender] += 1;
          this.userUnRead = { ...this.userUnRead };
        } else {
          this.userUnRead[data.sender] = 1;
          this.userUnRead = { ...this.userUnRead };
        }
      }

      console.log("unread", this.userUnRead);

      this.active_chat.username = data.sender;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = data.sender;
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { sender: data.sender });
      // globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", data.sender);
      if (this.active_chat.user_to == data.sender) {
        await this.active_chat.messages.push({
          to: globalThis.username,
          from: data.sender,
          message: data.message,
          time: new Date().getTime(),
        });
      }
      var audio = new Audio("sounds/alert-2.wav");
      audio.play();

      this.scrollToBottom();
    });
    globalThis._voyce.$qm.on("ChatMessageTargetError", (data) => {
      console.log("ChatMessageTargetError", data);
    });
    globalThis._voyce.$qm.emit("Chat:GetAllUsers", globalThis.username);
    globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", globalThis.username);

    globalThis._voyce.$qm.on("AnnouncementMessageResponse", async (data) => {
      console.log("AnnouncementMessageResponse");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "DUYURU",
          icon: 'Volume2Icon',
          text: data.message,
          variant: 'primary',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          toastClassName: "toast-property",

        });
      let anons = [
        {
          announcements: {
            Timestamp: data.Timestamp,
            msg: data.message,
          },
          sender: data.sender,
        },
      ];

      this.announcements = [...anons, ...this.announcements];
      console.log(data);
      var audio = new Audio("sounds/ambient-drop.wav");
      audio.play();
    });
    globalThis._voyce.$qm.on("SouffleMessageResponse", async (data) => {

      var audio = new Audio("sounds/ambient-drop.wav");
      audio.play();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.sender.split("@")[0] + " size özel bir mesaj gönderdi.",
          icon: 'InfoIcon',
          text: data.message,
          variant: 'warning',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          toastClassName: "toast-property-warning",
        });
    });
    globalThis._voyce.$qm.on("GetAllUsersResponse", async (data) => {
      // this.chat_users = data.userList;
      console.log("chatusers,", this.chat_users)
    });

    globalThis._voyce.$qm.on("ChatRegisterResponse", (data) => {
      this.online_users = this.agent_list;
    });

    globalThis._voyce.$qm.on("GetUnreadFromResponse", (data) => {
      console.log("GetUnreadFromResponse", data);
    });

    if (globalThis.v_navbar) {
      this.agent_list = globalThis.v_navbar.agent_list;
    }


    // });

    globalThis.LayoutContentRendererDefault.qm_socket_connected = globalThis.v_navbar.qm_socket.connected;
  },
  beforeDestroy: function () {
    clearInterval(this.transfer_interval);
    clearInterval(this.calendarInterval);
    clearInterval(this.acw_interval);
    clearInterval(this.extra_time_interval);
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    globalThis._voyce.$qm.removeAllListeners();
    // globalThis._voyce.$qm.disconnect();


  },

  setup() {
    const { routerTransition, contentWidth } = useAppConfig()
    return {
      routerTransition, contentWidth
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.time {
  display: block;
  text-align: right;
  font-size: 10px;
}

#chat {
  position: absolute;
  background: transparent;
  right: 12px;
  bottom: 0;
  width: 390px;
}

.userslist {
  height: 420px;
  overflow: auto;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newchatlist {
  max-height: 500px;
  width: 100%;
  overflow: auto;
}

.startchat:nth-child(even) {
  background-color: transparent;
}

.chat-panel-container {
  background: transparent;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 95%;
  z-index: 2;
}

.chat_panel_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.messages {
  background: transparent;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.input-area {
  border-top: 1px solid transparent;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-area input {
  width: 100%;
  height: 100%;
  outline: 0;
  background: $light;
  font-size: 1em;
  line-height: 1.05em;
  padding: 10px;
}

.msg {
  position: relative;
  display: block;
  min-height: 40px;
  height: auto;
  padding: 10px 10px;
  margin-bottom: 10px;
  width: 100%;
}

.msg_to {
  background: $primary;
  color: white;
  float: right;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 20px 20px 0 20px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.msg_from {
  background: $secondary;
  color: white;
  float: left;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px 20px 20px 0;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.msg_note {
  background: $warning;
  color: white;
  float: right;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px 20px 20px 0;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.user-unread {
  background: linear-gradient(
    126deg,
    rgba(38, 70, 83, 1) 0%,
    rgba(70, 128, 152, 1) 100%
  );
  position: absolute;
  right: 15px;
  padding: 10px;
  font-size: 14px;
  width: 30px;
  height: 30px;
  color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  color: #82868b !important;
}
.toast-property {
  margin-top: "75px";
  border: 2px solid;
}
.toast-property-warning {
  border: 2px solid #ff9f43;
}
.full-height {
  height: 100vh; /* This ensures it takes up the full viewport height */
}
</style>

