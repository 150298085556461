<template>
  <layout-vertical>
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <router-view style="max-height: 85vh; overflow-y: auto; overflow-x:hidden" />

    <app-customizer v-if="showCustomizer" slot="customizer" />
  </layout-vertical>
</template>main-menu

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import {
  BButtonGroup
} from 'bootstrap-vue'
export default {
  components: {
    BButtonGroup,
    AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>

 <style>
 /* ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.thin_scroll {
  scrollbar-width: none; /* thin */
}
</style>
